<ng-template [ngIf]="screenWidth > mobileScreenSize" [ngIfElse]="smallScreenDisplay">

    <div style="width:500px;background-color: white;padding: 25px 25px 25px 25px;">
    <div class="row">
      <div class="col-md-6">
        <span style="font-size:21px;"><strong>Andrew Thompson</strong></span><br />
        <span style="font-size:18px;top: -30px;">Legal</span>
      </div>
      <div class="col-md-6" style="text-align: right;">
        <img style="width: 150px;margin-top:3px;margin-right:20px;text-align: right;" src="../../assets/images/agv/shop-2.jpg"/>
      </div>
    </div>
    <!-- <h2 mat-dialog-title>Rex Geitner<br />AGV Viticulturist</h2><br />-->
    <mat-dialog-content style="margin-top:20px;" class="mat-typography">
    <p>
      At age 33, Andrew is the older of winery owner Peter Thompson's two sons. He graduated from the University of California, Merced, with a degree in Business. 
      Andrew attended and graduated from University of San Diego School of Law in May 2016 and passed the California Bar Exam on his first attempt in July 2016. 
      Andrew is an attorney and partner with Dunn, DeSantis, Walt and Kendrick in La Jolla, California practicing in the areas of corporate and business law with an emphasis on mergers and acquisitions for tech companies. 
      He continues to have a passion for computers, cars and international travel. Andrew also continues to be an important ambassador for Andrew Geoffrey Vineyards' and recently poured the AGV wines at the USD Wine Classic.
    </p>
    <div class="row" style="margin-top:20px;">
    <div class="col-md-12" style="text-align: center;">
        <img style="width:100px;" src="../../assets/images/agv/bottle-horizontal.png" />
    </div>


    </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button  class="btn btn-outline-secondary" [mat-dialog-close]="true" cdkFocusInitial>Close</button>
    </mat-dialog-actions>
    </div>

</ng-template>


<ng-template #smallScreenDisplay>
  <div style="width:200px;">
    <div class="row">
      <div class="col-md-6">
        <span style="font-size:21px;"><strong>Andrew Thompson</strong></span><br />
        <span style="font-size:18px;top: -30px;">Legal</span>
      </div>
      <div class="col-md-6" style="text-align: right;">
        <img style="width: 150px;margin-top:3px;margin-right:20px;text-align: right;" src="../../assets/images/agv/shop-2.jpg"/>
      </div>
    </div>
    <!-- <h2 mat-dialog-title>Rex Geitner<br />AGV Viticulturist</h2><br />-->
    <mat-dialog-content style="margin-top:20px;" class="mat-typography">
    <p>
      At age 33, Andrew is the older of winery owner Peter Thompson's two sons. He graduated from the University of California, Merced, with a degree in Business. 
      Andrew attended and graduated from University of San Diego School of Law in May 2016 and passed the California Bar Exam on his first attempt in July 2016. 
      Andrew is an attorney and partner with Dunn, DeSantis, Walt and Kendrick in La Jolla, California practicing in the areas of corporate and business law with an emphasis on mergers and acquisitions for tech companies. 
      He continues to have a passion for computers, cars and international travel. Andrew also continues to be an important ambassador for Andrew Geoffrey Vineyards' and recently poured the AGV wines at the USD Wine Classic.
    </p>
    <div class="row" style="margin-top:20px;">
    <div class="col-md-12" style="text-align: center;">
        <img style="width:100px;" src="../../assets/images/agv/bottle-horizontal.png" />
    </div>


    </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button  class="btn btn-outline-secondary" [mat-dialog-close]="true" cdkFocusInitial>Close</button>
    </mat-dialog-actions>
    </div>


</ng-template>
