
<app-nav-menu-home></app-nav-menu-home>
<!-- Wrapper-->
<div class="wrapper">
  <!-- Hero-->
  <section class="module-cover-slides" id="home" data-module-cover-slides-options="{&quot;animation&quot;: &quot;fade&quot;, &quot;play&quot;: 6000}">
      <ul class="slides-container">
          <li class="parallax text-center" data-overlay="0.2"><img src="assets/images/agv/agv-panoramo-5.jpg" alt="">
              <div class="container">
                  <div class="row">
                      <div class="col-md-12">
                          <h1 class="m-b-20"><strong>Diamond Mountain District Cabernet Sauvignon</strong></h1>
                          <h3 class="m-b-40">Andrew Geoffrey announces the release of the 2019 vintage</h3>
                          <p><a class="btn btn-sm btn-circle btn-new-white" href="#" (click)="navigateToSection('order')">Order Wine</a></p>
                      </div>
                  </div>
              </div>
          </li>
          <li class="parallax text-center" data-overlay="0.0"><img src="assets/images/agv/agv-panoramo-6.jpg" alt="">
              <div class="container">
                  <div class="row">
                      <div class="col-md-12">
                          <h1 class="m-b-20"><strong>Andrew Geoffrey’s Diamond Mountain Vineyards</strong></h1>
                          <p class="m-b-40" style="font-size:21px;">Andrew Geoffrey Vineyards has completed planting 3 additional acres.  Click the Vineyard Information button below to learn about newly planted blocks.</p>
                          <p>
                            <a class="btn btn-sm btn-circle btn-new-white" href="#" (click)="navigateToSection('vineyard')">Vineyard Information</a>
                            <a class="btn btn-sm btn-circle btn-new-white" href="#" (click)="navigateToSection('order')">Order Wine</a>
                            <!-- <a class="btn btn-sm btn-circle btn-new-white" href="#" (click)="navigateToSection('portfolio')">View Images/Fly over video of vineyards</a> -->
                          </p>
                      </div>
                  </div>
              </div>
          </li>
          <li class="parallax text-center" data-overlay="0.0"><img src="assets/images/agv/andrew-geoffrey-young-image.jpg" alt="">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                      <div style="margin-top:170px;"></div>
                        <h3 class="m-b-20"><strong>29 Years of Producing Quality Diamond Mountain Cabernet</strong></h3>
                        <p>
                          <a class="btn btn-sm btn-circle btn-new-white" href="#" (click)="navigateToSection('about')">Andrew Geoffrey History</a>
                          <a class="btn btn-sm btn-circle btn-new-white" href="#" (click)="navigateToSection('order')">Order Wine</a>

                        </p>
                    </div>
                </div>
            </div>
        </li>
        <li class="parallax text-center" data-overlay="0.2"><img src="assets/images/agv/panorama-7.jpg" alt="">
              <div class="container">
                  <div class="row">
                      <div class="col-md-12">
                        <div style="margin-top:100px;"></div>
                          <h3 class="m-b-20 m-t-20" style="padding-top:70px;"><strong>Get to know the Andrew Geoffrey team</strong></h3>
                          <p>
                            <a class="btn btn-sm btn-circle btn-new-white" href="#" (click)="navigateToSection('team')">Team</a>
                            <a class="btn btn-sm btn-circle btn-new-white" href="#" (click)="navigateToSection('order')">Order Wine</a>
                          </p>
                      </div>
                  </div>
              </div>
          </li>
          <li class="parallax text-center" data-overlay="0.2"><img src="../../assets/images/agv/agv-panoramo-1.jpg" alt="">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                      <div style="margin-top:100px;"></div>
                        <!-- <h3 class="m-b-20 m-t-20" style="padding-top:70px;"><strong>Check out our photos and video gallery</strong></h3> -->
                        <h3 class="m-b-20 m-t-20" style="padding-top:70px;"><strong>Andrew Geoffrey Photo and Video Gallery</strong></h3>
                        <!-- <a mat-button href="#" target="_blank" (click)="navigateToSection('gallery')"><h2>Andrew Geoffrey Photo and Video Gallery</h2></a><br /> -->
                        <a class="btn btn-sm btn-circle btn-new-white" href="#" (click)="navigateToSection('gallery')">Gallery</a>
                        
                    </div>
                </div>
            </div>
        </li>
      </ul>
      <div class="slides-navigation"><a class="prev" href="#"><span class="ti-angle-left"></span></a><a class="next" href="#"><span class="ti-angle-right"></span></a></div>
  </section>
  <!-- Hero end-->



  <!-- Gallery -->
  <section class="module p-b-0" id="gallery">
      <div class="container">
          <div class="row">
              <div class="col-md-12 m-auto text-center">
                  <h1>Andrew Geoffrey Vineyards</h1>
                  <!-- <p class="lead">Image and Video Gallery</p> -->
                  <!-- <p class="lead"><a routerLink="/gallery">View All Images/Video</a></p> -->
                  <a class="btn btn-sm btn-circle btn-new-white" href="#" (click)="navigateToSection('gallery')">View All Images and Video</a>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="space" data-MY="40px"></div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <ul class="filters serif">
                      <li><a class="current" href="#" data-filter="*">All</a></li>
                      <li><a href="#" data-filter=".vineyard">Vineyards</a></li>
                      <li><a href="#" data-filter=".team">Team</a></li>
                      <li><a href="#" data-filter=".wines">Wines</a></li>
                  </ul>
              </div>
          </div>
      </div>
      <div class="container-fluid">
          <div class="row row-portfolio" data-columns="3">
              <div class="grid-sizer"></div>
              
              <div class="portfolio-item vineyard">
                  <div class="portfolio-item-wrapper">
                      <div class="portfolio-item-img"><img src="assets/images/agv/about-2.jpg" alt=""></div>
                      <div class="portfolio-item-caption">
                          <h6 class="portfolio-item-title">See our vision become reality</h6><span class="portfolio-item-subtitle serif">History</span>
                      </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                  </div>
              </div>
              <div class="portfolio-item team">
                <div class="portfolio-item-wrapper">
                    <div class="portfolio-item-img"><img src="assets/images/agv/gallery-peter-andrew-geoff.jpg" alt=""></div>
                    <div class="portfolio-item-caption">
                        <h6 class="portfolio-item-title">Peter, Andrew and Geoffrey Thompson</h6><span class="portfolio-item-subtitle serif">AGV Team</span>
                    </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                </div>
            </div>
              <div class="portfolio-item vineyard">
                  <div class="portfolio-item-wrapper">
                      <div class="portfolio-item-img"><img src="assets/images/agv/vineyards-1.jpg" alt=""></div>
                      <div class="portfolio-item-caption">
                          <h6 class="portfolio-item-title">See where your wine begins</h6><span class="portfolio-item-subtitle serif">Vineyards</span>
                      </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                  </div>
              </div>
              <div class="portfolio-item team">
                  <div class="portfolio-item-wrapper">
                      <div class="portfolio-item-img"><img src="assets/images/agv/team-1.jpg" alt=""></div>
                      <div class="portfolio-item-caption">
                          <h6 class="portfolio-item-title">Andrew & Geoffrey Thompson</h6><span class="portfolio-item-subtitle serif">AGV Team</span>
                      </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                  </div>
              </div>
              <div class="portfolio-item team">
                <div class="portfolio-item-wrapper">
                    <div class="portfolio-item-img"><img src="assets/images/agv/gallery-agt-grt.jpg" alt=""></div>
                    <div class="portfolio-item-caption">
                        <h6 class="portfolio-item-title">Andrew & Geoffrey Thompson</h6><span class="portfolio-item-subtitle serif">AGV Team</span>
                    </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                </div>
            </div>
              <div class="portfolio-item wines">
                  <div class="portfolio-item-wrapper">
                      <div class="portfolio-item-img"><img src="assets/images/agv/shop-1.jpg" alt=""></div>
                      <div class="portfolio-item-caption">
                          <h6 class="portfolio-item-title">Purchase our wines</h6><span class="portfolio-item-subtitle serif">Shop</span>
                      </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                  </div>
              </div>
              <div class="portfolio-item wines">
                  <div class="portfolio-item-wrapper">
                      <div class="portfolio-item-img"><img src="assets/images/agv/shop-2.jpg" alt=""></div>
                      <div class="portfolio-item-caption">
                          <h6 class="portfolio-item-title">Varietals and Vintages</h6><span class="portfolio-item-subtitle serif">Wines</span>
                      </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                  </div>
              </div>
              <div class="portfolio-item wines">
                  <div class="portfolio-item-wrapper">
                      <div class="portfolio-item-img"><img src="assets/images/agv/gallery-1.jpg" alt=""></div>
                      <div class="portfolio-item-caption">
                          <h6 class="portfolio-item-title">Andrew Geoffrey Images and Video</h6><span class="portfolio-item-subtitle serif">Gallery</span>
                      </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                  </div>
              </div>
              <div class="portfolio-item team">
                <div class="portfolio-item-wrapper">
                    <div class="portfolio-item-img"><img src="assets/images/agv/peter-andrew-san-diego-wine-classic.jpg" alt=""></div>
                    <div class="portfolio-item-caption">
                        <h6 class="portfolio-item-title">Peter and Andrew pouring at the University of San Diego Wine Classic</h6><span class="portfolio-item-subtitle serif">Gallery</span>
                    </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                </div>
            </div>
           
          </div>
      </div>
  </section>
  <!-- Gallery end-->
<div style="margin-top:40px;">
&nbsp;
</div>
  <hr />

  <!-- History Start -->
<section class="module divider-bottom" id="about" #agvhistory>
  <div class="container">
      <div class="row">
        <div class="col-md-6 m-auto text-center">
          <h1>Andrew Geoffrey History</h1>
      </div>

      <div class="col-12 py-3">
        <p>Andrew Geoffrey Vineyards’ 13 acres of red wine grapes are situated within a 66-acre parcel of prime hillside vineyard property on the highest slopes of the Diamond Mountain District sub-appellation of the Napa Valley.
          Diamond Mountain is located along the Mayacamas mountain range between St. Helena and Calistoga. The Diamond Mountain sub-appellation is well known and recognized for its diversity of volcanic soils,
          varied micro-climates, and generous amounts of winter rainfall. Cabernet Sauvignon grapes grown on Diamond Mountain have an outstanding reputation for high quality and Diamond Mountain wines produced
          from those Cabernet Sauvignon grapes possess strong consumer recognition.
        </p>

        <p>
          Since visiting the Napa Valley in 1973, while attending college in southern California, Andrew Geoffrey Vineyards’ owner, <a href="#" (click)="navigateToSection('team')">Peter Thompson</a>, set his sights on getting into the wine business in the Napa Valley.
          In 1992, Peter began actively pursuing his goal of owning a vineyard in the prestigious Napa Valley. After a two and a half year search for the ultimate vineyard property, Peter selected the Andrew Geoffrey Vineyards site atop Diamond Mountain.
          The property was undeveloped, but only needed the right combination of efforts to realize its incredible potential for creating a state-of-the-art vineyard to produce world class California Cabernet Sauvignon wine.
        </p>

        <p>
          Peter purchased the land in 1995 and set out to assemble a team of experienced professionals to carve the vineyard site out of the scrub, brush and forest native to Diamond Mountain.
          After a year of extensive planning, obtaining the appropriate permits and drafting of the most extensive erosion control plan ever devised in the Napa Valley,
          13 acres of land were cleared and prepared with the expertise and guidance of well-known Napa Valley viticulturist, <a href="#" (click)="navigateToSection('team')">Rex Geitner</a>.
        </p>

        <p>
          The first consideration for building a successful vineyard was to locate and tap into a consistent, non-seasonal water source for irrigation. After much consideration,
          a well site was picked and, after an unsuccessful drilling attempt, Andrew Geoffrey Vineyards became home to one of the deepest wells (850 feet) in the Napa Valley. The result was a well with an abundance of ice-cold, clear,
          and chemical free water that does not need treatment before use. On a hot day, the vineyard workers regularly fill up their water bottles from the spigot at the well head with cold drinking water.
        </p>

        <p>
          The unique combination of soils (volcanic ash and tufa rock) and varying exposures (Northeast, North and Northwest) required an extensive investigation into locating and then purchasing exactly the right rootstock and red grape clones.
          Rex’s selection process was exacting, but required to optimize use of the land and micro-climates within the vineyard. In 1996, Peter purchased the rootstock and clones selected by Rex. In late July 1997,
          sectional planting of one year old bench grafted rootstock/budstock was completed.
        </p>

        <p>
          The vineyard has spacing of 8 feet between rows and 5 feet between vines and was originally comprised of:
        </p>
        <ul  style="margin-left:40px;">
          <li  style="list-style-type: disc">
            sixty-eight percent (68%) Cabernet Sauvignon, clones 337 and 15, both on 110r rootstock,
          </li>
          <li  style="list-style-type: disc;">
            eighteen percent (18%) Cabernet Franc of a proprietary clone on 110r rootstock,
          </li>
          <li  style="list-style-type: disc;">
            twelve percent (12%) Merlot clones 181 and
          </li>
          <li  style="list-style-type: disc;">
            a proprietary clone of rare quality also on 110r rootstock, and two percent (2%) Petit Verdot clone 2 on 110r rootstock.
          </li>
        </ul>

        <p>
          Currently, after budding over (converting) the Merlot clones in 2003 to additional Cabernet Sauvignon and Petit Verdot, the vineyard is comprised of:
        </p>
        <ul style="margin-left:40px;">
          <li style="list-style-type: disc;">
            seventy-eight percent (78%) Cabernet Sauvignon, clones 337 and 15, both on 110r rootstock,
          </li>
          <li  style="list-style-type: disc;">
            eighteen percent (18%) Cabernet Franc of a proprietary clone on 110r rootstock, and
          </li>
          <li  style="list-style-type: disc;">
            four percent (4%) Petit Verdot clone 2 on 110r rootstock.
          </li>
        </ul>

        <p>
          In April of 2020, Andrew Geoffrey Vineyards began to implement its long-term plan to plant an additional 3 acres (approximately) of vines, which had been approved by Napa County back in 1996.
          Clearing for the additional acreage took place in May 2020, with completion of the vineyard installation to be the following season in May 2021.  However, mother nature had other plans.
        </p>

        <p>
          In late September 2020, the Glass Fire burned through and over the top of Diamond Mountain, and thus, burned through and around Andrew Geoffrey Vineyards. 
          The property suffered substantial losses, including its fences and gates, sheds, vineyard equipment, the staging platform, furniture and irrigation system. Approximately 250 perimeter vines were damaged and will need to be replanted in 2027 or 2028 when the vineyard replanting 
          on a 7 year staged basis begins. As a result of the Glass fire, the planned 3 acre vineyard expansion was delayed one season from 2020 to 2021
        </p>

        <p>
          After assessing the damage from the Glass Fire, Peter decided to rebuild/repair the vineyard infrastructure, and, additionally, press on with the planting of new vineyard blocks 8 and 9. The first order of business was to secure the original vineyard blocks, 
          which required rebuilding the vineyard fence. Next, most of the irrigation system was replaced as the irrigation lines had melted during the fire. Essential equipment has been replaced and the staging platform rebuilt. The small shed has been rebuilt and the larger sheds will be replaced over the next few years.
        </p>

        <p>
          The new blocks of vines were next. The first new block is located slightly down the mountain, at the Northwest corner of the 66 acres, and forms a separate vineyard from the original vineyard. This lower block, designated “Block 9,” 
          is approximately 1.1 acres and is spaced at 6 feet between rows and 4 feet between vines. It has been planted with 110r rootstock, which has been field grafted in 2022 to Cabernet Sauvignon Clone 337 with cuttings from the original vineyard.
        </p>

        <p>
          The new upper block, designated “Block 8,” features two different row directions to take advantage of drainage in the porous volcanic ash and tufa soils. The total area of Block 8 is approximately 1.9 
          acres and has been planted with 110r rootstock. Approximately one half of Block 8 has been grafted to additional Petite Verdot and the other one half grafted to Cabernet Sauvignon Clone 169. Grafting took place during the 2022 season.  
          Peter and John are expecting a small crop from new blocks 8 and 9 in 2024 and a commercially viable crop in 2025.
        </p>

        <p>
          For the 2023-2024 vintages, Andrew Geoffrey Vineyards will be selling Cabernet Sauvignon grapes to Ron and Sue-Marie Haber at Haber Family Vineyards (Tim Milos, winemaker), 
          to Flint Knoll Winery and Moone Tsai Winery (Philippe Melka, winemaker for both projects), and Alta Nova (Brittany Nichols, winemaker).  
          Peter and <a href="#" (click)="navigateToSection('team')">John Gibson</a> work closely with our grape purchasers and consider them valuable partners in making incredible Napa Valley wines.
        </p>

      </div>

      </div>
  </div>
</section>
<!-- End History-->



  <!-- Team-->
  <section class="module" id="team">
      <div class="container">
          <div class="row">
              <div class="col-md-6 m-auto text-center">
                  <h1>Andrew Geoffrey Team</h1>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="space" data-MY="60px"></div>
              </div>
          </div>
          <div class="row">
              <div class="col-lg-4 col-md-6">
                  <div class="team-item">
                      <div class="team-image"><img src="assets/images/agv/team-peter.jpg" alt="">
                          <div class="team-wrap hover-button" (click)="DialogPeter()">
                              <div class="team-content">
                                  <h6 class="team-name">Peter Thompson</h6>
                                  <div class="team-role">Proprietor</div>
                              </div>
                              <!-- <div class="team-content-social">
                                  <ul>
                                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                      <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                  </ul>
                              </div> -->
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6">
                  <div class="team-item">
                      <div class="team-image"><img src="assets/images/agv/john-gibson-bio-image.jpg" alt="">
                          <div class="team-wrap hover-button" (click)="DialogJohn()">
                              <div class="team-content">
                                  <h6 class="team-name">John Gibson</h6>
                                  <div class="team-role">Winemaker</div>
                              </div>
                              <!-- <div class="team-content-social">
                                  <ul>
                                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                      <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                  </ul>
                              </div> -->
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6">
                  <div class="team-item">
                      <div class="team-image"><img src="assets/images/agv/rex-bio-image.jpg" alt="">
                          <div class="team-wrap hover-button" (click)="DialogRex()">
                              <div class="team-content">
                                  <h6 class="team-name">Rex Geitner</h6>
                                  <div class="team-role">Viticulturist</div>
                              </div>
                              <!-- <div class="team-content-social">
                                  <ul>
                                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                      <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                  </ul>
                              </div> -->
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6">
                  <div class="team-item">
                      <div class="team-image"><img src="assets/images/agv/karen-bio.jpg" alt="">
                          <div class="team-wrap hover-button" (click)="DialogKaren()">
                              <div class="team-content">
                                  <h6 class="team-name">Karen Petersen</h6>
                                  <div class="team-role">Direct Sales Manager</div>
                              </div>
                              <!-- <div class="team-content-social">
                                  <ul>
                                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                      <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                  </ul>
                              </div> -->
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6">
                  <div class="team-item">
                      <div class="team-image"><img src="assets/images/agv/shop-2.jpg" alt="">
                          <div class="team-wrap hover-button" (click)="DialogAllison()">
                              <div class="team-content">
                                  <h6 class="team-name">Allison Lake</h6>
                                  <div class="team-role">Sales</div>
                              </div>
                              <!-- <div class="team-content-social">
                                  <ul>
                                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                      <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                  </ul>
                              </div> -->
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6">
                  <div class="team-item">
                      <div class="team-image"><img src="assets/images/agv/shop-2.jpg" alt="">
                          <div class="team-wrap hover-button"  (click)="DialogAndrew()">
                              <div class="team-content">
                                  <h6 class="team-name">Andrew Thompson</h6>
                                  <div class="team-role">Legal</div>
                              </div>
                              <!-- <div class="team-content-social">
                                  <ul>
                                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                      <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                  </ul>
                              </div> -->
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="team-item">
                    <div class="team-image"><img src="assets/images/agv/geoff-thompson-bio.png" alt="">
                        <div class="team-wrap hover-button"   (click)="DialogGeoffrey()" >
                            <div class="team-content">
                                <h6 class="team-name">Geoffrey Thompson</h6>
                                <div class="team-role">Culinary</div>
                            </div>
                            <!-- <div class="team-content-social">
                                <ul>
                                    <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                    <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="space" data-MY="30px"></div>
              </div>
          </div>
          <!-- <div class="row">
              <div class="col-md-12">
                  <p class="text-center"><a class="btn btn-circle btn-outline-brand" href="#">About Us</a></p>
              </div>
          </div> -->
      </div>
  </section>
  <!-- Team end-->

  <hr />
    <!-- Vineyard Start -->
<section class="module divider-bottom" id="vineyard">
  <div class="container">

      <div class="row">
        <div class="col-md-6 m-auto text-center">
          <h1>The Vineyards</h1>
      </div>
      </div>
      <div class="row" style="margin-top:30px;">
        <h4>Vineyard Varietal Breakdown by Percentage</h4>

        <div class="col-12">
          <p>Total acres = 13 +/-</p>
        </div>
        <div class="col-12">
          <p>The current composition of the vineyard is:</p>
        </div>

        <div class="col-6">
          Cabernet Sauvignon 110r rootstock (Blocks 1-7a, 7b and 7c)
        </div>
        <div class="col-6">
          78%
        </div>

        <div class="col-6">
          Cabernet Franc 110r rootstock (proprietary clone)
        </div>
        <div class="col-6">
          18%
        </div>

        <div class="col-6">
          Petit Verdot 110r rootstock (Clone 2)
        </div>
        <div class="col-6">
          4%
        </div>


        <div class="col-12 mt-3">
          <p>Total acres = 3 Planted 2021 (Blocks 8 and 9)</p>
        </div>
        <div class="col-12">
          <p>The current composition of the new planting is:</p>
        </div>

        <div class="col-6">
          Cabernet Sauvignon  110r rootstock (Clone 337)
        </div>
        <div class="col-6">
          32%
        </div>

        <div class="col-6">
          Cabernet Sauvignon 110r rootstock (Clone 169)
        </div>
        <div class="col-6">
          37%
        </div>

        <div class="col-6">
          Petit Verdot 110r rootstock (Clone 2)
        </div>
        <div class="col-6">
          31%
        </div>

        <div class="col-12 mt-3">
          Andrew Geoffrey Vineyards currently sells grapes to the following Wineries: Haber Family Winery, Moone Tsai, Flint Knoll, and Alta Nova.  
        </div>

        <div class="col-12 mt-1">
          Peter and John are expecting a small crop from new blocks 8 and 9 in 2024 and a commercially viable crop in 2025.
        </div>
      </div>

      <div class="row" style="margin-top:30px;">
        <div class="col-md-6" >
          <img src="../../assets/images/agv/lower-vineyard.jpg" style="height:350px;width:500px"><br />
          <p>Lower Vineyard</p>
        </div>
        <div class="col-md-6">
          <img src="../../assets/images/agv/upper-vineyard.jpg"  style="height:350px;width:500px;"><br />
          <p>Upper Vineyard</p>
        </div>
        </div>
        <div class="row" style="margin-top:40px;">
        <div class="col-md-6" >
          <img src="../../assets/images/agv/lower-vineyard-block-9.png" style="height:350px;width:500px"><br />
          <p>Block 9 Planted</p>
        </div>
        <div class="col-md-6">
          <img src="../../assets/images/agv/vineyard-in-fog.png"  style="height:350px;width:500px;"><br />
          <p>Block 8 Planted</p>
        </div>
      </div>

  </div>
</section>
<!-- End Vineyard-->



 


  <!-- Events Wines -->
  <section class="module divider-bottom" id="wine">
    <div class="container">
        <div class="row">
          <div class="col-md-6 m-auto text-center">
            <h1>Andrew Geoffrey Wine</h1>
          </div>
        </div>

        <div class="row">
          <p>
            Andrew Geoffrey Vineyards is proud to announce the release of its 2020 Diamond Mountain District Estate Cabernet Sauvignon on October 1, 2024. John Gibson, 
            Andrew Geoffrey's Consulting winemaker, and Peter Thompson, Andrew Geoffrey Vineyards owner and co-winemaker, have blended the finest lots produced from the varietals grown in the vineyard to produce a sophisticated, 
            balanced and age worthy wine. The wine is currently available for sale only in 750ml bottle format.
          </p>
        </div>

        <!-- <hr style="width:60%;margin-left:auto;margin-right:auto;margin-top:30px;margin-bottom:30px;" /> -->
        <div style="width:125px;margin-left:auto;margin-right:auto;margin-top:75px;margin-bottom:35px;">
          <img src="../../assets//images//agv/bottle-horizontal.png">
        </div>

        <div class="row">
          <p style="font-style:italic;font-size:16px;">
            Current Vintage Wine - 2020 Cabernet Sauvignon
          </p>
        </div>

        <div class="row" style="margin-bottom:20px;">
          <p>
            <strong>2020 Cabernet Sauvignon Winemaker Notes</strong>
          </p>
        </div>

        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Harvest dates:
          </div>
          <div class="col-md-8">
            September 26, 2020
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Harvest analysis:
          </div>
          <div class="col-md-8">
            26.3 Brix, 4.2 gms/l TA, 3.77 pH
          </div>
        </div>


        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Crushing Regime:
          </div>
          <div class="col-md-8">
            50% whole berries
          </div>
        </div>


        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Pre-Fermentation Maceration:
          </div>
          <div class="col-md-8">
            7 days @ 50 degrees F.
          </div>
        </div>


        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Pumpover Regime:
          </div>
          <div class="col-md-8">
            2 pumpovers/day with aeration
          </div>
        </div>


        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Maximum Fermentation Temperature:
          </div>
          <div class="col-md-8">
            88 degrees F
          </div>
        </div>


        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Total Skin Contact Time:
          </div>
          <div class="col-md-8">
            28 days
          </div>
        </div>


        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Barrel Aging:
          </div>
          <div class="col-md-8">
            20 months in French oak barrels, 84% new from the Center of France and Allier Forests with Heavy and Medium Plus Toast
          </div>
        </div>


        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Blend Composition:
          </div>
          <div class="col-md-8">
            91% Cabernet Sauvignon, 9% Petit Verdot
          </div>
        </div>

        <div class="row" style="margin-top:20px;">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Bottling Date::
          </div>
          <div class="col-md-8">
            August 19, 2022
          </div>
        </div>



        <!-- <hr style="width:60%;margin-left:auto;margin-right:auto;margin-top:30px;margin-bottom:30px;" /> -->
        <div style="width:125px;margin-left:auto;margin-right:auto;margin-top:75px;margin-bottom:35px;">
          <img src="../../assets//images//agv/bottle-horizontal.png">
        </div>

        <div class="row">
          <p style="font-style:italic;font-size:16px;">
            Featured Library Vintage Wine - 2005 Cabernet Sauvignon
          </p>
        </div>

        <div class="row" style="margin-bottom:20px;">
          <p>
            <strong>2005 Cabernet Sauvignon Winemaker Notes:</strong>
          </p>
        </div>

        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Harvest dates:
          </div>
          <div class="col-md-8">
            October 5, 2005 to October 25, 2005
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Harvest analysis:
          </div>
          <div class="col-md-8">
            26.25 Brix, 5.1 gms/l TA, 3.87 pH
          </div>
        </div>


        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Crushing Regime:
          </div>
          <div class="col-md-8">
            50% whole berries
          </div>
        </div>


        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Pre-Fermentation Maceration:
          </div>
          <div class="col-md-8">
            4 days @ 55 degrees F.
          </div>
        </div>


        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Pumpover Regime:
          </div>
          <div class="col-md-8">
            2 times per day with Aeration until dryness
          </div>
        </div>


        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Maximum Fermentation Temperature:
          </div>
          <div class="col-md-8">
            90 degrees F
          </div>
        </div>


        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Total Skin Contact Time:
          </div>
          <div class="col-md-8">
            37 days
          </div>
        </div>


        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Barrel Aging:
          </div>
          <div class="col-md-8">
            80% new French Oak, Allier and center of France/medium plus toast
          </div>
        </div>


        <div class="row">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Blend Composition:
          </div>
          <div class="col-md-8">
            91% Cabernet Sauvignon, 7% Cabernet Franc, 2% Petit Verdot
          </div>
        </div>

        <div class="row" style="margin-top:20px;">
          <div class="col-md-4 text-right" style="font-weight:bold;">
            Bottling Date::
          </div>
          <div class="col-md-8">
            July 2, 2007
          </div>
        </div>

        <div class="row" style="margin-top:30px;">
          <p>
            The 2005 growing season in the Napa Valley was spectacular.  With adequate rainfall from the winter months, budbreak took place in late March and early April.  A warm summer with temperatures hovering in the high 80's followed. The vines developed a large crop and significant crop thinning was required just after verasion to maintain fruit concentration and intensity of flavors.  In September, the weather cooled slightly, thus extending the growing season into October, with the additional time for the fruit to hang allowing flavors to develop to their fullest.  Picking began in 2005 with the Cabernet Sauvignon on October 5th and finished on October 17th.  Cabernet Franc was harvested on October 15th and Petit Verdot on October 25th.  The extended hang time insured that the fruit was as ripe as possible with mature tannins.
          </p>
        </div>


    </div>
  </section>
  <!-- End Wines-->

   <!-- Events Wines -->
  

    <!-- Events Start -->
  <section class="module divider-bottom" id="events">
    <div class="container">
      <div class="row">
        <div class="col-md-6 m-auto text-center">
          <h1>Events</h1>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 m-auto">
          <h2 style="margin-top: 20px;">Calendar of Events</h2>
        </div>


       

        <!-- <div class="col-md-6 m-auto">
          <p style="font-size:18px;">June 17-23, 2023</p>
        </div>
        <div class="col-md-6 m-auto">
          <p  style="font-size:18px;">Croatia Adriatic Cruise</p>
        </div> -->

          <div class="col-md-6 m-auto">
          <p style="font-size:18px;">May 3-10, 2025</p>
        </div>
        <div class="col-md-6 m-auto">
          <p  style="font-size:18px;">Rhone Valley Wine Cruise</p>
        </div>

      </div>



      <div class="row" style="margin-top:100px;">
        <div class="col-md-12 m-auto">
          <h2>Wine Experiences & Trips</h2>
        </div>

        <div class="col-md-12 m-auto">

          <div class="row">
          
            <div class="col-md-6">
              <p style="font-size:18px;"><strong>Sail the Idyllic Rhone - May 3-10, 2025</strong></p>
              <p style="font-size:18px;"><a href="http://andrewgeoffrey.com/assets/download/Rhone-AGV.pdf" target="_blank">Click here for more information on this trip</a></p>
              <img src="assets/images/agv/trip-rhone.jpg"/>
            </div>

        </div>
      </div>
      </div>


    </div>
  </section>
  <!-- End Events-->



  <!-- Form-->
  <section class="module divider-top" id="contact">
      <div class="container">
          <!-- <div class="row">
              <div class="col-lg-6 col-md-9 m-auto text-center">
                  <h1>Let's talk.</h1>
                  <p class="lead">Send us a message about our vineyard, our wines or visiting Napa Valley.</p>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="space" data-MY="60px"></div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-8 m-auto">
                  <form id="contact-form" method="post" novalidate>
                      <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                              <select class="form-control" required="">
                                <option selected="selected">Select Email Recipient</option>
                                <option>Peter Thompson</option>
                                <option>Karen Petersen</option>
                                <option>Sara Livermore</option>
                                <option>General Information</option>
                            </select>
                                <p class="help-block text-danger"></p>
                            </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <input class="form-control" type="text" name="name" placeholder="Name" required="">
                                  <p class="help-block text-danger"></p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <input class="form-control" type="email" name="email" placeholder="E-mail" required="">
                                  <p class="help-block text-danger"></p>
                              </div>
                          </div>
                          <div class="col-md-12">
                              <div class="form-group">
                                  <input class="form-control" type="text" name="subject" placeholder="Subject" required="">
                                  <p class="help-block text-danger"></p>
                              </div>
                          </div>
                          <div class="col-md-12">
                              <div class="form-group">
                                  <textarea class="form-control" name="message" placeholder="Message" rows="12" required=""></textarea>
                              </div>
                          </div>
                          <div class="col-md-12">
                              <p>
                                  <input class="btn btn-block btn-secondary" type="submit" value="Send Message">
                              </p>
                          </div>
                      </div>
                  </form>

                  <div class="ajax-response text-center" id="contact-response"></div>
              </div>
          </div>
          <hr /> -->
          <div class="row" style="margin-top:40px;">
            <div class="col-md-6">
              <h5>Email Addresses</h5>
              <div class="row">
                <div class="col-md-6">
                    Peter
                </div>
                <div class="col-md-6">
                  <a href="mailto:peter@andrewgeoffrey.com">peter@andrewgeoffrey.com</a>
                </div>

                <div class="col-md-6">
                  Karen (sales/orders)
                </div>
                <div class="col-md-6">
                  <a href="mailto:karen@andrewgeoffrey.com">karen@andrewgeoffrey.com</a>
                </div>

                <div class="col-md-6">
                  Allison Lake
                </div>
                <div class="col-md-6">
                  <a href="mailto:allison@andrewgeoffrey.com">allison@andrewgeoffrey.com</a>
                </div>

                <div class="col-md-6">
                  General Information
                </div>
                <div class="col-md-6">
                  <a href="mailto:info@andrewgeoffrey.com">info@andrewgeoffrey.com</a>
                </div>

              </div>
            </div>
            <div class="col-md-6">
              <h5>Telephone</h5>

              <div class="row">
                <div class="col-md-9">
                    Peter (direct to mobile)
                </div>
                <div class="col-md-3">
                    619-954-8687
                </div>

                <div class="col-md-9">
                  Karen (sales/orders)
                </div>
                <div class="col-md-3">
                  707-363-3675
                </div>

                <div class="col-md-9">
                  Allison
                </div>
                <div class="col-md-3">
                  650-400-8445
                </div>

                <div class="col-md-9">
                  AGV Office (vendors/general information)
                </div>
                <div class="col-md-3">
                  707-968-9770
                </div>

              </div>
            </div>
            </div>

            <div class="row" style="margin-top:40px;">
            <div class="col-md-6">
              <h5>Office Mailing Address</h5>
              <div class="row">
                <div class="col-md-12">
                Andrew Geoffrey Vineyards<br />
                836 Chiles Avenue<br />
                St. Helena, CA <br />
                94574<br />
                (Administrative and Mailing Address Only)
              </div>
              </div>
            </div>

            <div class="col-md-6">
              <h5>Vineyard Address</h5>
              <div class="row">
                <div class="col-md-12">
                Andrew Geoffrey Vineyards<br />
                1769 Diamond Mountain Road<br />
                Calistoga, CA <br />
                94515<br />
              </div>
              </div>
            </div>
          </div>
      </div>
  </section>
  <!-- Form end-->




</div>
<!-- Wrapper end-->
<!-- Off canvas-->
<div class="off-canvas-sidebar">
  <div class="off-canvas-sidebar-wrapper">
      <div class="off-canvas-header"><a class="off-canvas-close" href="#"><span class="ti-close"></span></a></div>
      <div class="off-canvas-content">
          <!-- Text widget-->
          <aside class="widget widget-text">
              <div class="textwidget">
                  <p class="text-center"><img src="assets/images/logo-light-2.png" alt="" width="80px"></p>
                  <p class="text-center">See how users experience your website in realtime to see changes.</p>
              </div>
          </aside>
          <!-- Recent portfolio widget-->
          <aside class="widget widget-recent-works">
              <ul>
                  <li><a href="#"><img src="assets/images/widgets/1.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/2.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/3.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/4.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/5.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/6.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/7.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/8.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/9.jpg" alt=""></a></li>
              </ul>
          </aside>
          <!-- Social Icons-->
          <ul class="social-icons text-center">
              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
              <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
              <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
          </ul>
      </div>
  </div>
</div>
<!-- Off canvas end-->

<!-- To top button--><a class="scroll-top" href="#top"><i class="fas fa-angle-up"></i></a>


