import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { BioPeterComponent } from '../team-bio/bio-peter.component';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  constructor(
      private viewportScroller: ViewportScroller,
      public dialog: MatDialog) { }

  ngOnInit(): void {
  }


  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  openDialog() {
    const dialogRef = this.dialog.open(BioPeterComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
