  <div style="width:500px;background-color: white;padding: 25px 25px 25px 25px">
    <div class="row">
      <div class="col-md-6">
        <span style="font-size:21px;"><strong>Geoffrey Thompson</strong></span><br />
        <span style="font-size:18px;top: -30px;">Culinary</span>
      </div>
      <div class="col-md-6" style="text-align: right;">
        <img style="width: 150px;margin-top:3px;margin-right:20px;text-align: right;" src="../../assets/images/agv/shop-2.jpg"/>
      </div>
    </div>
    <!-- <h2 mat-dialog-title>Rex Geitner<br />AGV Viticulturist</h2><br />-->
    <mat-dialog-content style="margin-top:20px;" class="mat-typography">
    <p>
      Geoff, age 30, is the younger son of winery owner Peter Thompson. Geoff has had a passion for cooking since he was 12. At 15, he began working for Chef Michael Chiarello on the line at Bottega Ristorante in Yountville and 
      thereafter with Chef Nick Richie at Restaurant Alex in Rutherford. Geoff attended and graduated from the Culinary Institute of America in Hyde Park, NY with his Bachelor of Science degree in Culinary Arts. 
      While at the CIA, Geoff interned for Chef Frank Stitt at Bottega Cafe and Highlands Grill in Birmingham, Alabama. 
      After graduating from the CIA, Geoff began cooking for Executive Chef/Owner Paul Kahan and Chef de Cuisine Ryan Pfeiffer at Michelin starred Blackbird Restaurant in Chicago, Illinois. 
      At the time Blackbird closed due to Covid 19, Geoff was the Executive Sous Chef. He is currently working Culinary Director for 16” On Center, a restaurant ownership group in Chicago. 
      Geoff speaks kitchen Spanish and Italian, and aspires to become chef/owner of his own restaurant. Geoff is often the chef for Andrew Geoffrey Vineyards' private home dinners across the country. 
      Geoff has interned at the winery on two occassions and has worked with Peter and John to blend the 2020, 2021 and 2022 vinetages.
    </p>
    <div class="row" style="margin-top:20px;">
     <div class="col-md-12" style="text-align: center;">
        <img style="width:100px;" src="../../assets/images/agv/bottle-horizontal.png" />
     </div>


    </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button  class="btn btn-outline-secondary" [mat-dialog-close]="true" cdkFocusInitial>Close</button>
    </mat-dialog-actions>
    </div>



