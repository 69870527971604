import { Component, HostListener,  OnInit } from '@angular/core';

@Component({
  selector: 'app-bio-andrew',
  templateUrl: './bio-andrew.component.html',
  styleUrls: ['./bio-andrew.component.scss']
})
export class BioAndrewComponent implements OnInit {
  midScreenSize: number = 1400;
  mobileScreenSize: number = 790;
  screenWidth: number = 0;
  screenHeight: number = 0;

  constructor() { }

  ngOnInit(): void {

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

}
