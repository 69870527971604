  <div style="width:500px;background-color: white; padding: 25px 25px 25px 25px">
    <div class="row">
      <div class="col-md-6">
        <span style="font-size:21px;"><strong>John Gibson</strong></span><br />
        <span style="font-size:18px;top: -30px;">Winemaker</span>
      </div>
      <div class="col-md-6" style="text-align: right;">
        <img style="width: 150px;margin-top:3px;margin-right:20px;text-align: right;" src="../../assets/images/agv/john-gibson-bio-image.jpg"/>
      </div>
    </div>
    <!-- <h2 mat-dialog-title>Rex Geitner<br />AGV Viticulturist</h2><br />-->
    <mat-dialog-content style="margin-top:20px;" class="mat-typography">
    <p>
      John is a veteran Napa Valley winemaker having served as winemaker at Stag&rsquo;s Leap Wine Cellars from 1985 to 1993 and
      then at Vine Cliff Winery from 1994 to 1997. John left Vine Cliff to focus on individual small winery projects, including his own Chardonnay and Merlot project, Salexis.
    </p>
    <p>
      John has been the guiding light for winemaking at Andrew Geoffrey Vineyards. With the support of Viticulturalist Rex Geitner,
      John has laid out the path for the future of Andrew Geoffrey Vineyards&rsquo; wines. A strong believer in letting the vines mature gradually and evenly,
      John worked with Rex to target the appropriate amount of fruit each vine should be allowed to produce in the first 5 to 7 years, thus keeping the vines in balance.
      The holding back of the vine production has already paid dividends by yields of intense fruit and giving John a wide variety of varietal blending options from the vineyard.
    </p>
    <p>
      John also has started a program of fermenting and barreling each of the clones separately, to further add to the blending options to make the
      best Andrew Geoffrey wine possible,without regard to quotas for production. Each varietal clone is kept separate and allowed to mature in the barrel for at
      least eight months before blending.There are no formulas for Andrew Geoffrey wine. The blend will vary year to year, but will always be a Cabernet Sauvignon based wine.
      Simply put, whether it be one case or two thousand cases, John will only allow the highest quality wine be bottled as Andrew Geoffrey Vineyards Cabernet Sauvignon.
    </p>
    <div class="row" style="margin-top:20px;">
     <div class="col-md-12" style="text-align: center;">
        <img style="width:100px;" src="../../assets/images/agv/bottle-horizontal.png" />
     </div>


    </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button  class="btn btn-outline-secondary" [mat-dialog-close]="true" cdkFocusInitial>Close</button>
    </mat-dialog-actions>
    </div>
