<app-nav-menu></app-nav-menu>
<div class="wrapper">

  <section class="module-cover parallax" id="home" data-background="../../assets/images/agv/agv-bottle-gallery-header.png"  data-overlay="0.2">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h3><strong>Wines Available for Purchase</strong></h3>
                <p style="font-size:18px;font-weight: 400;margin-top:00px;">Please contact us for vintage availability of larger format bottles</p>
            </div>
        </div>
    </div>
</section>


<section class="module divider-bottom" id="order"  >
    <div class="container-full" style="margin-left:200px;margin-right:170px;">

      
      <div class="row" style="margin-bottom:0px;">

        <div class="col-md-4 py-3">
          <span style="font-size:18px;"><strong>Vintage</strong></span>
        </div>


        <div class="col-md-1 py-3">
          <span style="font-size:18px;"><strong>Size</strong></span>
        </div>

        <div class="col-md-1 py-3 text-right">
          <span style="font-size:18px;"><strong>Price</strong></span>
        </div>

        <div class="col-md-2 py-0">
        </div>
       
        <div class="col-md-2 text-right">
        </div>
        
    </div>


        <div *ngFor="let aProductVintage of productList;let ii = index" style="margin-bottom:0px;">

            <div *ngIf="isNewVintage(aProductVintage.vintage)">
              <div class="row" style="height:40px;">
                <div class="col-12">
                 <hr />
                </div>
              </div>
            </div>
          
          <div class="row">
            <div class="col-md-4 py-3">
              {{aProductVintage.productDisplayName}}
            </div>

            <div class="col-md-1 py-3">
              {{aProductVintage.bottleSizeDescription}}
            </div>

            <div class="col-md-1 py-3 text-right">
              {{aProductVintage.price | currency}}
            </div>

            <div class="col-md-3 py-0 ml-4">
              <input type="number" class="form-control" id="quantity{{ii}}" placeholder="Quantity">
            </div>
           
            <div class="col-md-2 text-right">
              <button class="btn btn-outline-secondary" (click)="addToCart(ii, aProductVintage.productId)">Add to Cart</button>
            </div>
            
          </div> 
          <!-- end row -->

        </div>
  
    </div>
</section>

</div>



  <!-- <mat-form-field class="example-full-width">
    <mat-label>Favorite food</mat-label>
    <input matInput placeholder="Ex. Pizza" value="Sushi">
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Leave a comment</mat-label>
    <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
  </mat-form-field>
</form> -->
  
