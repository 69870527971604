import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { ProductVintage } from '../../../entities/ProductVintage';
import { IProduct } from '../../../shared/interfaces';

const API_URL = 'https://localhost:44380/api/vintages';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
    providedIn: 'root'
  })
  export class VintageService {
    Products: IProduct[] = [];
    public NumberItems = 0;
    productsUrl: string = API_URL + '/GetAllWinesAvailable';
    productVintageUrl: string = API_URL;
  
    constructor(private http: HttpClient) {
    }
  

    getVintagesForWebstore(): Observable<ProductVintage[]> {
        return this.http.get<ProductVintage[]>(this.productVintageUrl);
   }


}
