 <!-- Preloader-->
 <!-- <div class="page-loader">
  <div class="page-loader-inner">
      <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
      </div>
  </div>
</div> -->
<!-- Preloader end-->

<header class="header header-transparent">
  <div class="container-fluid">
      <!-- Brand-->
      <div class="inner-header"><a class="inner-brand" href="index.html"></a></div>
      <!-- Navigation-->
      <div class="inner-navigation collapse">
          <div class="inner-nav onepage-nav">
              <ul>
                  <li><a [routerLink] = "['/home']"><span class="menu-item-span">Home</span></a></li>
                  <li><a [routerLink] = "['/history']"><span class="menu-item-span">History</span></a></li>
                  <li><a href="#portfolio"><span class="menu-item-span">Team</span></a></li>
                  <li><a href="#services"><span class="menu-item-span">Vineyard</span></a></li>
                  <li><a href="#team"><span class="menu-item-span">Events</span></a></li>
                  <li><a href="#blog"><span class="menu-item-span">Wine</span></a></li>
                  <li><a href="#contact"><span class="menu-item-span">Order</span></a></li>
                  <li><a href="start.html"><span class="menu-item-span">Contact</span></a></li>
              </ul>
          </div>
      </div>
      <div class="extra-nav">
          <ul>
              <!-- <li><a class="off-canvas-open" href="#"><span class="menu-item-span"><i class="ti-menu"></i></span></a></li> -->
              <li class="nav-toggle"><a href="#" data-toggle="collapse" data-target=".inner-navigation"><span class="menu-item-span"><i class="ti-menu"></i></span></a></li>
          </ul>
      </div>
  </div>
</header>

<div class="wrapper">
  <!-- Hero-->
  <section class="module-cover-slides" id="home" data-module-cover-slides-options="{&quot;animation&quot;: &quot;fade&quot;, &quot;play&quot;: 6000}">
      <ul class="slides-container">
          <li class="parallax text-center" data-overlay="0.5"><img src="assets/images/agv/agv-panoramo-5.jpg" alt="">
              <div class="container">
                  <div class="row">
                      <div class="col-md-12">
                          <h1 class="m-b-20"><strong>2015 Diamond Mountain District Cabernet Sauvignon</strong></h1>
                          <p class="m-b-40">Andrew Geoffrey is proud to announce the release of the 2015 vintage.</p>
                          <p><a class="btn btn-lg btn-circle btn-outline-new-white" [routerLink] = "['/history']">Shop</a></p>
                      </div>
                  </div>
              </div>
          </li>
          <li class="parallax text-center" data-overlay="0.0"><img src="assets/images/agv/agv-panoramo-3.jpg" alt="">
              <div class="container">
                  <div class="row">
                      <div class="col-md-12">
                          <h1 class="m-b-20"><strong>The Andrew Geoffrey Vineyard</strong></h1>
                          <p class="m-b-40">Andrew Geoffrey Vineyards is pleased to announce that an additional 3 acres has been being planted.</p>
                          <p><a class="btn btn-lg btn-circle btn-warning" [routerLink] = "['/history']">Shop</a><a class="btn btn-lg btn-circle btn-outline-new-white" [routerLink] = "['/history']">Vineyard Information</a></p>
                      </div>
                  </div>
              </div>
          </li>
          <li class="parallax text-center" data-overlay="0.7"><img src="assets/images/agv/ag-2.jpg" alt="">
              <div class="container">
                  <div class="row">
                      <div class="col-md-12">
                          <h1 class="m-b-20"><strong>Andrew Geoffrey Vineyards’ are situated on the highest slopes of Diamond Mountain.</strong></h1>
                          <!-- <p class="m-b-40">See how your users experience your website in realtime or view  <br> trends to see any changes in performance over time.</p> -->
                          <p><a class="btn btn-lg btn-circle btn-new-white" [routerLink] = "['/history']">Wine Library</a><a class="btn btn-lg btn-circle btn-outline-new-white" [routerLink] = "['/history']">Team</a></p>
                      </div>
                  </div>
              </div>
          </li>
      </ul>
      <div class="slides-navigation"><a class="prev" href="#"><span class="ti-angle-left"></span></a><a class="next" href="#"><span class="ti-angle-right"></span></a></div>
  </section>
  <!-- Hero end-->

  <!-- About-->
  <!-- <section class="module divider-bottom" id="about">
      <div class="container">
          <div class="row">
              <div class="col-md-8 m-auto text-center">
                  <h1>Andrew Geoffrey Vineyards</h1>
                  <p class="lead">One to two sentence description.  </p>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="space" data-MY="60px"></div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12 text-center">
                <video max-width="960" height="auto" controls>
                  <source src="assets/video/vineyard.mov" type="video/mp4">
                Your browser does not support the video tag.
                </video>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="space" data-mY="30px"></div>
              </div>
          </div>
          <div class="row">
              <div class="col-lg-3 col-md-6">
                  <div class="icon-box text-center">
                      <div class="icon-box-icon"><span class="ti-gallery"></span></div>
                      <div class="icon-box-title">
                          <h6>Vineyard</h6>
                      </div>
                      <div class="icon-box-content">
                          <p>Sixty acres at the top of Diamond Mountain in Napa Valley.</p>
                      </div>
                      <div class="icon-box-link"><a href="#">more</a></div>
                  </div>
              </div>
              <div class="col-lg-3 col-md-6">
                  <div class="icon-box text-center">
                      <div class="icon-box-icon"><span class="ti-user"></span></div>
                      <div class="icon-box-title">
                          <h6>Team</h6>
                      </div>
                      <div class="icon-box-content">
                          <p>The Andrew Geoffrey team farming and producing our wines.</p>
                      </div>
                      <div class="icon-box-link"><a href="#">more</a></div>
                  </div>
              </div>
              <div class="col-lg-3 col-md-6">
                  <div class="icon-box text-center">
                      <div class="icon-box-icon"><span class="ti-cup"></span></div>
                      <div class="icon-box-title">
                          <h6>Wines</h6>
                      </div>
                      <div class="icon-box-content">
                          <p>Detailed information about our vintages and varietals.</p>
                      </div>
                      <div class="icon-box-link"><a href="#">more</a></div>
                  </div>
              </div>
              <div class="col-lg-3 col-md-6">
                  <div class="icon-box text-center">
                      <div class="icon-box-icon"><span class="ti-shopping-cart"></span></div>
                      <div class="icon-box-title">
                          <h6>Shop</h6>
                      </div>
                      <div class="icon-box-content">
                          <p>Browse and purchase wines from our online store.</p>
                      </div>
                      <div class="icon-box-link"><a href="#">more</a></div>
                  </div>
              </div>
          </div>
      </div>
  </section> -->
  <!-- About end-->

  <!-- Portfolio-->
  <section class="module p-b-0" id="portfolio">
      <div class="container">
          <div class="row">
              <div class="col-md-6 m-auto text-center">
                  <h1>Andrew Geoffrey Vineyards</h1>
                  <p class="lead">Peter verbiage here...</p>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="space" data-MY="40px"></div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <ul class="filters serif">
                      <li><a class="current" href="#" data-filter="*">All</a></li>
                      <li><a href="#" data-filter=".vineyard">Vineyard</a></li>
                      <li><a href="#" data-filter=".team">Team</a></li>
                      <li><a href="#" data-filter=".wines">Wines</a></li>
                  </ul>
              </div>
          </div>
      </div>
      <div class="container-fluid">
          <div class="row row-portfolio" data-columns="3">
              <div class="grid-sizer"></div>
              <div class="portfolio-item vineyard">
                  <div class="portfolio-item-wrapper">
                      <div class="portfolio-item-img"><img src="assets/images/agv/about-2.jpg" alt=""></div>
                      <div class="portfolio-item-caption">
                          <h6 class="portfolio-item-title">See our vision become reality</h6><span class="portfolio-item-subtitle serif">History</span>
                      </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                  </div>
              </div>
              <div class="portfolio-item team">
                  <div class="portfolio-item-wrapper">
                      <div class="portfolio-item-img"><img src="assets/images/agv/vineyards-1.jpg" alt=""></div>
                      <div class="portfolio-item-caption">
                          <h6 class="portfolio-item-title">See where your wine begins</h6><span class="portfolio-item-subtitle serif">Vineyards</span>
                      </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                  </div>
              </div>
              <div class="portfolio-item vineyard">
                  <div class="portfolio-item-wrapper">
                      <div class="portfolio-item-img"><img src="assets/images/agv/team-1.jpg" alt=""></div>
                      <div class="portfolio-item-caption">
                          <h6 class="portfolio-item-title">Get to know us</h6><span class="portfolio-item-subtitle serif">Our Team</span>
                      </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                  </div>
              </div>
              <div class="portfolio-item wines">
                  <div class="portfolio-item-wrapper">
                      <div class="portfolio-item-img"><img src="assets/images/agv/shop-1.jpg" alt=""></div>
                      <div class="portfolio-item-caption">
                          <h6 class="portfolio-item-title">Purchase our wines</h6><span class="portfolio-item-subtitle serif">Shop</span>
                      </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                  </div>
              </div>
              <div class="portfolio-item wines">
                  <div class="portfolio-item-wrapper">
                      <div class="portfolio-item-img"><img src="assets/images/agv/shop-with-title-2.jpg" alt=""></div>
                      <div class="portfolio-item-caption">
                          <h6 class="portfolio-item-title">Varietals and Vintages</h6><span class="portfolio-item-subtitle serif">Wines</span>
                      </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                  </div>
              </div>
              <div class="portfolio-item vineyard">
                  <div class="portfolio-item-wrapper">
                      <div class="portfolio-item-img"><img src="assets/images/agv/gallery-1.jpg" alt=""></div>
                      <div class="portfolio-item-caption">
                          <h6 class="portfolio-item-title">Andrew Geoffrey Images and Video</h6><span class="portfolio-item-subtitle serif">Gallery</span>
                      </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <!-- Portfolio end-->

<!-- History Start -->
<section class="module divider-bottom" id="about">
      <div class="container">
          <div class="row">
            <div class="col-md-6 m-auto text-center">
              <h1>Andrew Geoffrey History</h1>
          </div>

          <div class="col-12 py-3">
            <p>
              Andrew Geoffrey Vineyards’ 13 acres of red wine grapes are situated within a 60 acre parcel of prime hillside vineyard property on the highest slopes of the Diamond Mountain District sub-appellation of the Napa Valley. Diamond Mountain is located along the Mayacamas mountain range between St. Helena and Calistoga. Diamond Mountain is well known and recognized for its diversity of volcanic soils, varied micro-climates, and generous amounts of winter rainfall. Red wine grapes grown on Diamond Mountain have an outstanding reputation and Diamond Mountain red wines possess a strong consumer recognition.
            </p>
            <p>
              Since visiting the Napa Valley in 1973, while attending college in southern California, Peter Thompson set his sights on getting into the wine business, specifically in the Napa Valley. In 1992, Peter began actively pursuing his goal of owning a vineyard in the prestigious Napa Valley. During a two and one-half year search for the ultimate vineyard property, Peter selected the Andrew Geoffrey Vineyards site atop Diamond Mountain. The property was undeveloped, but only needed the right combination of efforts to realize its incredible potential for creating a state-of-the-art vineyard to produce world class California Cabernet Sauvignon wine.
            </p>

            <p>
              With the undeveloped land purchased in 1995, Peter set out to assemble a team of experienced professionals to carve the vineyard site out of the scrub and brush native to the Diamond Mountain soils. After a year of extensive planning, obtaining the appropriate permits and drafting of the most extensive erosion control plan ever devised in the Napa Valley, 13 acres of land were cleared and prepared with the expertise and guidance of well-known Napa Valley viticulturist Rex Geitner. (For more about Rex, see below).
            </p>

            <p>
              The first consideration for building a successful state-of-the-art vineyard was to locate and tap into a consistent, non-seasonal water source for irrigation. After much consideration, a well site was picked and, after an unsuccessful drilling attempt, Andrew Geoffrey became home to one of the deepest wells (850 feet) in the Napa Valley. The payoff however was a well with ice cold, clear, mineral and chemical free water that needs no treatment before use and which produces more water than can be pumped out. On a hot day, the vineyard workers regularly fill up their water bottles from the spigot at the well head with cold drinking water.
            </p>

            <p>
              The unique combination of soils (volcanic ash and loam) and varying exposures (Northeast, North and Northwest) required an extensive investigation into locating and then purchasing exactly the right rootstock and red grape clones. Rex’s selection process was exacting, but required, to optimize use of the land and micro-climates within the vineyard. In 1996, Peter purchased the rootstock and clones selected by Rex. In late July 1997, sectional planting was completed.
            </p>

            <p>
              The vineyard was originally comprised of sixty-eight percent (68%) Cabernet Sauvignon, clones 337 and 15, both on 110r rootstock, eighteen percent (18%) Cabernet Franc of a proprietary clone on 110r rootstock, along with twelve percent (12%) Merlot clones 181 and a proprietary clone of uncommon quality also on 110r rootstock, and two percent (2%) Petit Verdot clone 2 on 110r rootstock. Currently, after budding over the Merlot clones in 2003 to additional Cabernet Sauvignon and Petit Verdot, the vineyard is comprised of seventy-eight percent (78%) Cabernet Sauvignon, clones 337 and 15, both on 110r rootstock, eighteen percent (18%) Cabernet Franc of a proprietary clone on 110r rootstock, and four percent (4%) Petit Verdot clone 2 on 110r rootstock.
            </p>
          </div>

          </div>
      </div>
  </section>
  <!-- End History-->




  <!-- Team-->
  <section class="module" id="team">
      <div class="container">
          <div class="row">
              <div class="col-md-6 m-auto text-center">
                  <h1>Andrew Geoffrey Team</h1>
                  <p class="lead">See how your users experience your website in realtime or view trends to see any changes in performance over time.</p>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="space" data-MY="60px"></div>
              </div>
          </div>
          <div class="row">
              <div class="col-lg-4 col-md-6">
                  <div class="team-item">
                      <div class="team-image"><img src="assets/images/team/1.jpg" alt="">
                          <div class="team-wrap">
                              <div class="team-content">
                                  <h6 class="team-name">Emma Connor</h6>
                                  <div class="team-role">Designer</div>
                              </div>
                              <div class="team-content-social">
                                  <ul>
                                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                      <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6">
                  <div class="team-item">
                      <div class="team-image"><img src="assets/images/team/2.jpg" alt="">
                          <div class="team-wrap">
                              <div class="team-content">
                                  <h6 class="team-name">Richard Bauer</h6>
                                  <div class="team-role">Developer</div>
                              </div>
                              <div class="team-content-social">
                                  <ul>
                                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                      <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6">
                  <div class="team-item">
                      <div class="team-image"><img src="assets/images/team/3.jpg" alt="">
                          <div class="team-wrap">
                              <div class="team-content">
                                  <h6 class="team-name">John Pope</h6>
                                  <div class="team-role">Founder</div>
                              </div>
                              <div class="team-content-social">
                                  <ul>
                                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                      <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6">
                  <div class="team-item">
                      <div class="team-image"><img src="assets/images/team/4.jpg" alt="">
                          <div class="team-wrap">
                              <div class="team-content">
                                  <h6 class="team-name">Sara Smith</h6>
                                  <div class="team-role">Designer</div>
                              </div>
                              <div class="team-content-social">
                                  <ul>
                                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                      <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6">
                  <div class="team-item">
                      <div class="team-image"><img src="assets/images/team/5.jpg" alt="">
                          <div class="team-wrap">
                              <div class="team-content">
                                  <h6 class="team-name">Emma Connor</h6>
                                  <div class="team-role">Developer</div>
                              </div>
                              <div class="team-content-social">
                                  <ul>
                                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                      <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6">
                  <div class="team-item">
                      <div class="team-image"><img src="assets/images/team/6.jpg" alt="">
                          <div class="team-wrap">
                              <div class="team-content">
                                  <h6 class="team-name">Peter Chapman</h6>
                                  <div class="team-role">Founder</div>
                              </div>
                              <div class="team-content-social">
                                  <ul>
                                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                      <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="space" data-MY="30px"></div>
              </div>
          </div>
          <!-- <div class="row">
              <div class="col-md-12">
                  <p class="text-center"><a class="btn btn-circle btn-outline-brand" href="#">About Us</a></p>
              </div>
          </div> -->
      </div>
  </section>
  <!-- Team end-->

  <!-- Testimonials-->
  <!-- <section class="module parallax" data-background="assets/images/module-12.jpg" data-overlay="0.5">
      <div class="container">
          <div class="row">
              <div class="col-md-12">
                  <div class="space" data-MY="50px"></div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="review-slides owl-carousel">
                      <div class="review">
                          <div class="review-icons"><img src="assets/images/avatar/1.jpg" alt="">
                          </div>
                          <div class="review-content">
                              <blockquote>
                                  <p>I don't work from drawings. I don't make sketches and drawings <br/> and color sketches into a final painting.</p>
                                  <footer class="blockquote-footer">Albert Einstein</footer>
                              </blockquote>
                          </div>
                      </div>
                      <div class="review">
                          <div class="review-icons"><img src="assets/images/avatar/1.jpg" alt="">
                          </div>
                          <div class="review-content">
                              <blockquote>
                                  <p>To be yourself in a world that is constantly trying to make you <br/> something else is the greatest accomplishment.</p>
                                  <footer class="blockquote-footer">Ralph Waldo Emerson</footer>
                              </blockquote>
                          </div>
                      </div>
                      <div class="review">
                          <div class="review-icons"><img src="assets/images/avatar/1.jpg" alt="">
                          </div>
                          <div class="review-content">
                              <blockquote>
                                  <p>Imperfection is beauty, madness is genius and it's better to be <br/> absolutely ridiculous than absolutely boring.</p>
                                  <footer class="blockquote-footer">Marilyn Monroe</footer>
                              </blockquote>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="space" data-MY="50px"></div>
              </div>
          </div>
      </div>
  </section> -->
  <!-- Testimonials end-->

  <!-- Clients-->
  <!-- <section class="module bg-gray p-t-0 p-b-40">
      <div class="container">
          <div class="space" data-MY="-60px"></div>
          <div class="row">
              <div class="col-md-4 pricing-wrapper">
                  <div class="pricing-header">
                      <div class="pricing-icon"><i class="ti-slice"></i></div>
                      <div class="pricing-title">Starter</div>
                  </div>
                  <div class="pricing-price">Free
                  </div>
                  <div class="pricing-body">
                      <p>Map where your photos<br>were taken and discover local<br>points of interest.</p>
                  </div>
                  <div class="pricing-footer"><a class="btn btn-circle btn-brand" href="#">Download</a></div>
              </div>
              <div class="col-md-4 pricing-wrapper pricing-popular">
                  <div class="pricing-header">
                      <div class="pricing-icon"><i class="ti-pin-alt"></i></div>
                      <div class="pricing-title">Standard</div>
                  </div>
                  <div class="pricing-price"><span>$</span>45
                  </div>
                  <div class="pricing-body">
                      <p>Map where your photos<br>were taken and discover local<br>points of interest.</p>
                  </div>
                  <div class="pricing-footer"><a class="btn btn-circle btn-brand" href="#">Download</a></div>
              </div>
              <div class="col-md-4 pricing-wrapper pricing-popular">
                  <div class="pricing-header">
                      <div class="pricing-icon"><i class="ti-marker-alt"></i></div>
                      <div class="pricing-title">Professional</div>
                  </div>
                  <div class="pricing-price"><span>$</span>69
                  </div>
                  <div class="pricing-body">
                      <p>Map where your photos<br>were taken and discover local<br>points of interest.</p>
                  </div>
                  <div class="pricing-footer"><a class="btn btn-circle btn-brand" href="#">Download</a></div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-10 m-auto">
                  <div class="owl-carousel clients-carousel">
                      <div class="client"><img src="assets/images/clients/1.png" alt=""></div>
                      <div class="client"><img src="assets/images/clients/2.png" alt=""></div>
                      <div class="client"><img src="assets/images/clients/3.png" alt=""></div>
                      <div class="client"><img src="assets/images/clients/4.png" alt=""></div>
                      <div class="client"><img src="assets/images/clients/5.png" alt=""></div>
                      <div class="client"><img src="assets/images/clients/6.png" alt=""></div>
                      <div class="client"><img src="assets/images/clients/7.png" alt=""></div>
                  </div>
              </div>
          </div>
      </div>
  </section> -->
  <!-- Clients end-->

  <!-- Posts-->
  <!-- <section class="module" id="blog">
      <div class="container">
          <div class="row">
              <div class="col-md-6 m-auto text-center">
                  <h1>Latest articles.</h1>
                  <p class="lead">See how your users experience your website in realtime or view trends to see any changes in performance over time.</p>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="space" data-MY="60px"></div>
              </div>
          </div>
          <div class="row row-post-masonry">
              <div class="col-md-4 post-item">

                  <article class="post">
                      <div class="post-preview"><a href="#"><img src="assets/images/blog/1.jpg" alt=""></a></div>
                      <div class="post-wrapper">
                          <div class="post-header">
                              <h2 class="post-title"><a href="blog-single.html">Bluetooth Speaker</a></h2>
                          </div>
                          <div class="post-content">
                              <p>See how your users experience your website in realtime or view trends to see any changes in performance over time.</p>
                              <p><a href="#">Read more</a></p>
                          </div>
                      </div>
                  </article>

              </div>
              <div class="col-md-4 post-item">

                  <article class="post">
                      <div class="post-preview"><a href="#"><img src="assets/images/blog/3.jpg" alt=""></a></div>
                      <div class="post-wrapper">
                          <div class="post-header">
                              <h2 class="post-title"><a href="blog-single.html">Minimalist Chandelier</a></h2>
                          </div>
                          <div class="post-content">
                              <p>See how your users experience your website in realtime or view trends to see any changes in performance over time.</p>
                              <p><a href="#">Read more</a></p>
                          </div>
                      </div>
                  </article>

              </div>
              <div class="col-md-4 post-item">

                  <article class="post">
                      <div class="post-preview"><a href="#"><img src="assets/images/blog/5.jpg" alt=""></a></div>
                      <div class="post-wrapper">
                          <div class="post-header">
                              <h2 class="post-title"><a href="blog-single.html">Standard Shape</a></h2>
                          </div>
                          <div class="post-content">
                              <p>See how your users experience your website in realtime or view trends to see any changes in performance over time.</p>
                              <p><a href="#">Read more</a></p>
                          </div>
                      </div>
                  </article>

              </div>
          </div>
      </div>
  </section> -->
  <!-- Posts end-->

  <!-- Form-->
  <section class="module divider-top" id="contact">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 col-md-9 m-auto text-center">
                  <h1>Let's talk.</h1>
                  <p class="lead">Send us a message about our vineyard, visitng Andrew Geoffrey, our wines or Napa.</p>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <div class="space" data-MY="60px"></div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-8 m-auto">
                  <form id="contact-form" method="post" novalidate>
                      <div class="row">
                          <div class="col-md-6">
                              <div class="form-group">
                                  <input class="form-control" type="text" name="name" placeholder="Name" required="">
                                  <p class="help-block text-danger"></p>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <input class="form-control" type="email" name="email" placeholder="E-mail" required="">
                                  <p class="help-block text-danger"></p>
                              </div>
                          </div>
                          <div class="col-md-12">
                              <div class="form-group">
                                  <input class="form-control" type="text" name="subject" placeholder="Subject" required="">
                                  <p class="help-block text-danger"></p>
                              </div>
                          </div>
                          <div class="col-md-12">
                              <div class="form-group">
                                  <textarea class="form-control" name="message" placeholder="Message" rows="12" required=""></textarea>
                              </div>
                          </div>
                          <div class="col-md-12">
                              <p>
                                  <input class="btn btn-block btn-brand" type="submit" value="Send Message">
                              </p>
                          </div>
                      </div>
                  </form>
                  <!-- Ajax response-->
                  <div class="ajax-response text-center" id="contact-response"></div>
              </div>
          </div>
      </div>
  </section>
  <!-- Form end-->

  <!-- Counters-->
  <!-- <section class="module bg-gray p-b-0">
      <div class="container">
          <div class="row">
              <div class="col-md-3">
                  <div class="counter">
                      <div class="counter-icon"><span class="icon-energy icons"></span></div>
                      <div class="counter-number">
                          <h6><strong class="counter-timer" data-from="0" data-to="1538">0</strong></h6>
                      </div>
                      <div class="counter-title">Happy Clients</div>
                  </div>
              </div>
              <div class="col-md-3">
                  <div class="counter">
                      <div class="counter-icon"><span class="icon-game-controller icons"></span></div>
                      <div class="counter-number">
                          <h6><strong class="counter-timer" data-from="0" data-to="2124">0</strong></h6>
                      </div>
                      <div class="counter-title">Total downloads</div>
                  </div>
              </div>
              <div class="col-md-3">
                  <div class="counter">
                      <div class="counter-icon"><span class="icon-fire icons"></span></div>
                      <div class="counter-number">
                          <h6><strong class="counter-timer" data-from="0" data-to="3128">0</strong></h6>
                      </div>
                      <div class="counter-title">Awards Won</div>
                  </div>
              </div>
              <div class="col-md-3">
                  <div class="counter">
                      <div class="counter-icon"><span class="icon-diamond icons"></span></div>
                      <div class="counter-number">
                          <h6><strong class="counter-timer" data-from="0" data-to="9534">0</strong></h6>
                      </div>
                      <div class="counter-title">Theme Users</div>
                  </div>
              </div>
          </div>
      </div>
  </section> -->
  <!-- Counters end-->

  <!-- Image-->
  <!-- <section class="module no-gutters p-0">
      <div class="container-fluid">
          <div class="row">
              <div class="col-md-12"><img src="assets/images/module-18.jpg" alt=""></div>
          </div>
      </div>
  </section> -->
  <!-- Image end-->

  <!-- Footer-->
  <footer class="footer">
      <div class="footer-widgets">
          <div class="container">
              <div class="row">
                  <div class="col-md-4">
                      <!-- Text widget-->
                      <aside class="widget widget-text">
                          <div class="widget-title">
                              <h6>About Us</h6>
                          </div>
                          <div class="textwidget">
                              <p>Map where your photos were taken and discover local points of interest. Map where your photos.</p>
                              <p>
                                  836 Chiles Ave Avenue, St. Helena, CA 94574<br>
                                  E-mail: info@andrewgeoffrey.com<br>
                                  Phone: 707.968.9770<br>
                              </p>
                              <!-- <ul class="social-icons">
                                  <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                  <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                  <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                  <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                              </ul> -->
                          </div>
                      </aside>
                  </div>
                  <div class="col-md-4">
                      <!-- Recent entries widget-->
                      <aside class="widget widget-recent-entries">
                          <div class="widget-title">
                              <h6>Events</h6>
                          </div>
                          <ul>
                              <li><a href="#">Wine Cruise.</a><span class="post-date">Jan 1, 2022</span></li>
                              <!-- <li><a href="#">Map where your photos were taken and discover local points.</a><span class="post-date">April 7, 2018</span></li>
                              <li><a href="#">Map where your photos were taken and discover local points.</a><span class="post-date">September 7, 2018</span></li> -->
                          </ul>
                      </aside>
                  </div>
                  <div class="col-md-4">
                      <!-- Twitter widget-->
                      <aside class="widget twitter-feed-widget">
                          <div class="widget-title">
                              <h6>Free Space/Maybe Disclaimer/Shopping Info</h6>
                          </div>
                          <!-- <div class="twitter-feed" data-twitter="double_theme" data-number="1"></div> -->
                      </aside>
                  </div>
                  <!-- <div class="col-md-3">
                      Recent works
                      <aside class="widget widget-recent-works">
                          <div class="widget-title">
                              <h6>Portfolio</h6>
                          </div>
                          <ul>
                              <li><a href="#"><img src="assets/images/widgets/1.jpg" alt=""></a></li>
                              <li><a href="#"><img src="assets/images/widgets/2.jpg" alt=""></a></li>
                              <li><a href="#"><img src="assets/images/widgets/3.jpg" alt=""></a></li>
                              <li><a href="#"><img src="assets/images/widgets/7.jpg" alt=""></a></li>
                              <li><a href="#"><img src="assets/images/widgets/8.jpg" alt=""></a></li>
                              <li><a href="#"><img src="assets/images/widgets/6.jpg" alt=""></a></li>
                          </ul>
                      </aside>
                  </div> -->
              </div>
          </div>
      </div>
      <div class="footer-bar">
          <div class="container">
              <div class="row">
                  <div class="col-md-12">
                      <div class="copyright">
                          <p>© 2021 Andrew Geoffrey Vineyards.</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </footer>
  <!-- Footer end-->
</div>
<!-- Wrapper end-->
<!-- Off canvas-->
<div class="off-canvas-sidebar">
  <div class="off-canvas-sidebar-wrapper">
      <div class="off-canvas-header"><a class="off-canvas-close" href="#"><span class="ti-close"></span></a></div>
      <div class="off-canvas-content">
          <!-- Text widget-->
          <aside class="widget widget-text">
              <div class="textwidget">
                  <p class="text-center"><img src="assets/images/logo-light-2.png" alt="" width="80px"></p>
                  <p class="text-center">See how users experience your website in realtime to see changes.</p>
              </div>
          </aside>
          <!-- Recent portfolio widget-->
          <aside class="widget widget-recent-works">
              <ul>
                  <li><a href="#"><img src="assets/images/widgets/1.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/2.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/3.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/4.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/5.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/6.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/7.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/8.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/9.jpg" alt=""></a></li>
              </ul>
          </aside>
          <!-- Social Icons-->
          <ul class="social-icons text-center">
              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
              <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
              <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
          </ul>
      </div>
  </div>
</div>
<!-- Off canvas end-->

<!-- To top button--><a class="scroll-top" href="#top"><i class="fas fa-angle-up"></i></a>

