<header class="header header-transparent">
  <div class="container-fluid">
      <!-- Brand-->
      <div class="inner-header"><a class="inner-brand" href="index.html"></a></div>
      <!-- Navigation-->
      <div class="inner-navigation collapse">
          <div class="inner-nav onepage-nav">
              <ul>
                  <li><a href="#"  routerLink="/home"><span class="menu-item-span">Home</span></a></li>
                  <li><a href="#" (click)="onClick('about')"><span class="menu-item-span">History</span></a></li>
                  <li><a href="#" (click)="onClick('team')"><span class="menu-item-span">Team</span></a></li>
                  <li><a href="#" (click)="onClick('vineyard')"><span class="menu-item-span">Vineyards</span></a></li>
                  <li><a href="#" (click)="onClick('events')" ><span class="menu-item-span">Events</span></a></li>
                  <li><a href="#" (click)="onClick('wine')"><span class="menu-item-span">Wine</span></a></li>
                  <li><a href="#" [routerLink]="'catalog'"><span class="menu-item-span">Order</span></a></li>
                  <li><a href="#" (click)="onClick('gallery')"><span class="menu-item-span">Gallery</span></a></li>
                  <li><a href="#" (click)="onClick('contact')"><span class="menu-item-span">Contact</span></a></li>
                  <li><a class="top-bar-cart" href="#"  routerLink="/cart"><span class="menu-item-span"><i class="ti-shopping-cart" style="margin-left:7px;"></i><span style="top: -3px;">{{this._storeStateService.cartQuantity}}</span></span></a></li>
              </ul>
          </div>
      </div>
      <div class="extra-nav">
          <ul>
              <!-- <li><a class="off-canvas-open" href="#"><span class="menu-item-span"><i class="ti-menu"></i></span></a></li> -->
              <li class="nav-toggle"><a href="#" data-toggle="collapse" data-target=".inner-navigation"><span class="menu-item-span"><i class="ti-menu"></i></span></a></li>
          </ul>
      </div>
  </div>
</header>

