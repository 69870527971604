import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bio-john',
  templateUrl: './bio-john.component.html',
  styleUrls: ['./bio-john.component.scss']
})
export class BioJohnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
