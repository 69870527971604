import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ICart } from 'src/app/shared/interfaces';


@Injectable()
export class StoreStateService {
    public cartData: ICart[] = [];
    public cartQuantity: number = 0;
    


    constructor(private http: HttpClient) {
        
    }

}