<app-nav-menu></app-nav-menu>

<div class="wrapper">

  <!-- Hero-->
  <section class="module-cover parallax fullscreen text-center" id="home" data-background="../../assets/images/module-2.jpg" data-jarallax-video="mp4:assets/video/vineyard-1.mp4,webm:assets/video/video.webm,ogv:assets/video/video.ogv" data-overlay="0.1">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="m-b-20"><strong>Andrew Geoffrey Vineyards<br> on Diamond Mountain.</strong></h1>
                <p><button class="btn btn-sm btn-circle btn-new-white" (click)="navigateToSection('page-gallery')">View All Images/Videos</button></p>
            </div>
        </div>
    </div>
</section>
<!-- Hero end-->




  <!-- Portfolio-->
  <section class="module p-t-35 p-b-0" id="page-gallery">
    <div class="container">
        <div class="row" style="margin-top:50px;">
            <div class="col-md-12">
                <ul class="filters serif">
                  <li><a class="current" href="#" data-filter="*">All</a></li>
                  <li><a href="#" data-filter=".vineyard">Vineyards</a></li>
                  <li><a href="#" data-filter=".team">Team</a></li>
                  <li><a href="#" data-filter=".wines">Wines</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
      <div class="row row-portfolio" data-columns="4">
        <div class="grid-sizer"></div>
        <div class="portfolio-item team">
            <div class="portfolio-item-wrapper">
                <div class="portfolio-item-img"><img src="assets/images/agv/peter-thompson-image-by-tank.png" alt=""></div>
                <div class="portfolio-item-caption">
                    <h6 class="portfolio-item-title">Peter Thompson</h6><span class="portfolio-item-subtitle serif">AGV Team</span>
                </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
            </div>
        </div>
        <div class="portfolio-item wines">
          <div class="portfolio-item-wrapper">
              <div class="portfolio-item-img"><img src="assets/images/agv/gallery-1.jpg" alt=""></div>
              <div class="portfolio-item-caption">
                  <h6 class="portfolio-item-title">Andrew Geoffrey Cabernet Sauvignon</h6><span class="portfolio-item-subtitle serif">Wine</span>
              </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
          </div>
      </div>
        <div class="portfolio-item vineyard">
            <div class="portfolio-item-wrapper">
                <div class="portfolio-item-img"><img src="assets/images/agv/vineyard-1-960-1440.png" alt=""></div>
                <div class="portfolio-item-caption">
                    <h6 class="portfolio-item-title">Andrew Geoffrey Vineyards on Diamond Mountain</h6><span class="portfolio-item-subtitle serif">Vineyards</span>
                </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
            </div>
        </div>
        <div class="portfolio-item team">
            <div class="portfolio-item-wrapper">
                <div class="portfolio-item-img"><img src="assets/images/agv/gallery-agt-grt.jpg" alt=""></div>
                <div class="portfolio-item-caption">
                    <h6 class="portfolio-item-title">Andrew & Geoffrey Thompson</h6><span class="portfolio-item-subtitle serif">AGV Team</span>
                </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
            </div>
        </div>
        <div class="portfolio-item vineyard">
          <div class="portfolio-item-wrapper">
              <div class="portfolio-item-img"><img src="assets/images/agv/vineyard-2-960-1440.png" alt=""></div>
              <div class="portfolio-item-caption">
                  <h6 class="portfolio-item-title">Andrew Geoffrey Vineyard on Diamond Mountain</h6><span class="portfolio-item-subtitle serif">Vineyards</span>
              </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
          </div>
      </div>
        <div class="portfolio-item wines">
            <div class="portfolio-item-wrapper">
                <div class="portfolio-item-img"><img src="assets/images/agv/agv-bottle-960-1440.png" alt=""></div>
                <div class="portfolio-item-caption">
                    <h6 class="portfolio-item-title">Purchase our wines</h6><span class="portfolio-item-subtitle serif">Shop</span>
                </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
            </div>
        </div>
        <div class="portfolio-item wines">
            <div class="portfolio-item-wrapper">
                <div class="portfolio-item-img"><img src="assets/images/agv/shop-1.jpg" alt=""></div>
                <div class="portfolio-item-caption">
                    <h6 class="portfolio-item-title">AGV Cabernet Sauvignon</h6><span class="portfolio-item-subtitle serif">Wines</span>
                </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
            </div>
        </div>
        <div class="portfolio-item team">
            <div class="portfolio-item-wrapper">
                <div class="portfolio-item-img"><img src="assets/images/agv/team-1.jpg" alt=""></div>
                <div class="portfolio-item-caption">
                    <h6 class="portfolio-item-title">8 Andrew and Geoffrey Thompson</h6><span class="portfolio-item-subtitle serif">AGV Team</span>
                </div><a class="portfolio-item-link" href="portfolio-single-1.html"></a>
            </div>
        </div>
    </div>
    </div>
</section>
<!-- Portfolio end-->






  <!-- Footer end-->
</div>
<!-- Wrapper end-->
<!-- Off canvas-->
<div class="off-canvas-sidebar">
  <div class="off-canvas-sidebar-wrapper">
      <div class="off-canvas-header"><a class="off-canvas-close" href="#"><span class="ti-close"></span></a></div>
      <div class="off-canvas-content">
          <!-- Text widget-->
          <aside class="widget widget-text">
              <div class="textwidget">
                  <p class="text-center"><img src="assets/images/logo-light-2.png" alt="" width="80px"></p>
                  <p class="text-center">See how users experience your website in realtime to see changes.</p>
              </div>
          </aside>
          <!-- Recent portfolio widget-->
          <aside class="widget widget-recent-works">
              <ul>
                  <li><a href="#"><img src="assets/images/widgets/1.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/2.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/3.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/4.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/5.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/6.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/7.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/8.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/9.jpg" alt=""></a></li>
              </ul>
          </aside>
          <!-- Social Icons-->
          <ul class="social-icons text-center">
              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
              <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
              <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
          </ul>
      </div>
  </div>
</div>
<!-- Off canvas end-->

<!-- To top button--><a class="scroll-top" href="#top"><i class="fas fa-angle-up"></i></a>
