<header class="header header-transparent">
  <div class="container-fluid">
      <!-- Brand-->
      <div class="inner-header"><a class="inner-brand" href="index.html"></a></div>
      <!-- Navigation-->
      <div class="inner-navigation collapse">
          <div class="inner-nav onepage-nav">
              <ul>
                  <li><a [routerLink] = "['/home']"><span class="menu-item-span">Home</span></a></li>
                  <li><a [routerLink] = "['/history']"><span class="menu-item-span">History</span></a></li>
                  <li><a href="#portfolio"><span class="menu-item-span">Team</span></a></li>
                  <li><a href="#services"><span class="menu-item-span">Vineyard</span></a></li>
                  <li><a href="#team"><span class="menu-item-span">Events</span></a></li>
                  <li><a href="#blog"><span class="menu-item-span">Wine</span></a></li>
                  <li><a href="#contact"><span class="menu-item-span">Order</span></a></li>
                  <li><a href="start.html"><span class="menu-item-span">Contact</span></a></li>
              </ul>
          </div>
      </div>
      <div class="extra-nav">
          <ul>
              <!-- <li><a class="off-canvas-open" href="#"><span class="menu-item-span"><i class="ti-menu"></i></span></a></li> -->
              <li class="nav-toggle"><a href="#" data-toggle="collapse" data-target=".inner-navigation"><span class="menu-item-span"><i class="ti-menu"></i></span></a></li>
          </ul>
      </div>
  </div>
</header>
