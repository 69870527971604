import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { StoreStateService } from '../core/services/state/store-state.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
cartQuantity: number = 0;

  constructor(private viewportScroller: ViewportScroller,
      public _storeStateService: StoreStateService
  ) { }

  ngOnInit(): void {
  }

  onClick(theAnchorTag: string){

  }


}
