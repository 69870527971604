<!-- Footer-->
<footer class="footer">
  <div class="footer-widgets">
      <div class="container">
          <div class="row">
              <div class="col-md-4">
                  <!-- Text widget-->
                  <aside class="widget widget-text">
                      <div class="widget-title">
                          <h6>About Us</h6>
                      </div>
                      <div class="textwidget">
                          <p>
                              836 Chiles Ave Avenue, St. Helena, CA 94574<br>
                              E-mail: info@andrewgeoffrey.com<br>
                              Phone: 707.968.9770<br>
                          </p>
                          <!-- <ul class="social-icons">
                              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                              <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                              <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                              <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                          </ul> -->
                      </div>
                  </aside>
              </div>
              <div class="col-md-4">
                  <!-- Recent entries widget-->
                  <aside class="widget widget-recent-entries">
                      <div class="widget-title">
                          <h6>Events</h6>
                      </div>
                      <ul>
                          <li><a href="https://agvnew.appsandbox.dev/assets/download/2024-italy-morocco-spain-trip.pdf" target="_blank">Spain Morocco Italy Cruise - April 17-27, 2024</a></li>

                      </ul>
                  </aside>
              </div>
              <div class="col-md-4">
                  <!-- Twitter widget-->
                  <aside class="widget twitter-feed-widget">
                      <div class="widget-title">
                          <h6>AGV Privacy Policy</h6>
                      </div>
                      <!-- <div class="twitter-feed" data-twitter="double_theme" data-number="1"></div> -->
                  </aside>
              </div>
              <!-- <div class="col-md-3">
                  Recent works
                  <aside class="widget widget-recent-works">
                      <div class="widget-title">
                          <h6>Portfolio</h6>
                      </div>
                      <ul>
                          <li><a href="#"><img src="assets/images/widgets/1.jpg" alt=""></a></li>
                          <li><a href="#"><img src="assets/images/widgets/2.jpg" alt=""></a></li>
                          <li><a href="#"><img src="assets/images/widgets/3.jpg" alt=""></a></li>
                          <li><a href="#"><img src="assets/images/widgets/7.jpg" alt=""></a></li>
                          <li><a href="#"><img src="assets/images/widgets/8.jpg" alt=""></a></li>
                          <li><a href="#"><img src="assets/images/widgets/6.jpg" alt=""></a></li>
                      </ul>
                  </aside>
              </div> -->
          </div>
      </div>
  </div>
  <div class="footer-bar">
      <div class="container">
          <div class="row">
              <div class="col-md-12">
                  <div class="copyright">
                      <p>© 2024 Andrew Geoffrey Vineyards.</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</footer>
