import { Component, OnInit } from '@angular/core';
import { StoreStateService } from '../core/services/state/store-state.service';
import { ICart } from '../shared/interfaces';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  cartData: ICart [];

  constructor(private _storeStateService: StoreStateService) {
    this.cartData = this._storeStateService.cartData;
   }

  ngOnInit(): void {
    this.loadScript('../assets/js/custom/custom.min.js');
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

}
