  <div style="width:500px;background-color: white; padding: 25px 25px 25px 25px">
    <div class="row">
      <div class="col-md-6">
        <span style="font-size:21px;"><strong>Karen Petersen</strong></span><br />
        <span style="font-size:18px;top: -30px;">Direct Sales Manager</span><br />
        <span style="font-size:14px;top: -30px;">
          <a href="mailto:karen@andrewgeoffrey.com">karen@andrewgeoffrey.com</a>
        </span>
      </div>
      <div class="col-md-6" style="text-align: right;">
        <img style="width: 150px;margin-top:3px;margin-right:20px;text-align: right;" src="../../assets/images/agv/shop-2.jpg"/>
      </div>
    </div>
    <!-- <h2 mat-dialog-title>Rex Geitner<br />AGV Viticulturist</h2><br />-->
    <mat-dialog-content style="margin-top:20px;" class="mat-typography">
    <p>
      I have been in the wine business for 20 years and I have had the privilege of selling AGV wines and working
      with Peter for over a decade.  The friendships I have made, the comradery I feel, and the
      smiles and laughs from all the AGV customers is a blessing to me.  When I am not busy selling the AGV wines
      I enjoy giving dinner parties, singing in church, gardening, reading crime fiction and writing the great American novel.
    </p>
    <div class="row" style="margin-top:20px;">
     <div class="col-md-12" style="text-align: center;">
        <img style="width:100px;" src="../../assets/images/agv/bottle-horizontal.png" />
     </div>


    </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button  class="btn btn-outline-secondary" [mat-dialog-close]="true" cdkFocusInitial>Close</button>
    </mat-dialog-actions>
    </div>

