<header class="header header-transparent">
  <div class="container-fluid">

      <div class="inner-header"><a class="inner-brand" href="index.html"></a></div>
      <div class="inner-navigation collapse">
          <div class="inner-nav onepage-nav">
              <ul>
                  <li><a href="#"  routerLink="/home"><span class="menu-item-span">Home</span></a></li>
                  <li><a href="#"  routerLink="/home"><span class="menu-item-span">History</span></a></li>
                  <li><a href="#"  routerLink="/home"><span class="menu-item-span">Team</span></a></li>
                  <li><a href="#" routerLink="/home"><span class="menu-item-span">Vineyards</span></a></li>
                  <li><a href="#" routerLink="/home" ><span class="menu-item-span">Events</span></a></li>
                  <li><a href="#" routerLink="/home"><span class="menu-item-span">Wine</span></a></li>
                  <li><a href="#" routerLink="/home"><span class="menu-item-span">Order</span></a></li>
                  <li><a href="#"><span class="menu-item-span">Gallery</span></a></li>
                  <li><a href="#" routerLink="/home"><span class="menu-item-span">Contact</span></a></li>
                  <li><a class="top-bar-cart" href="#" routerLink="/cart"><span class="menu-item-span"><i class="ti-shopping-cart" style="margin-left:7px;"></i><span style="top: -3px;">{{this._storeStateService.cartQuantity}}</span></span></a></li>
              </ul>
          </div>
      </div>
      <div class="extra-nav">
          <ul>
              <li class="nav-toggle"><a href="#" data-toggle="collapse" data-target=".inner-navigation"><span class="menu-item-span"><i class="ti-menu"></i></span></a></li>
          </ul>
      </div>
  </div>
</header>
