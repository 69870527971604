import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bio-sara',
  templateUrl: './bio-sara.component.html',
  styleUrls: ['./bio-sara.component.scss']
})
export class BioSaraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
