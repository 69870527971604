import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { StoreStateService } from '../core/services/state/store-state.service';

@Component({
  selector: 'app-nav-menu-home',
  templateUrl: './nav-menu-home.component.html',
  styleUrls: ['./nav-menu-home.component.scss']
})
export class NavMenuHomeComponent implements OnInit {
  cartQuantity: number = 0;

  constructor(private viewportScroller: ViewportScroller,
    public _storeStateService: StoreStateService
  ) { }

  ngOnInit(): void {
  }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

}
