<div style="width:500px;background-color: white;; padding: 25px 25px 25px 25px">
  <div class="row">
    <div class="col-md-6">
      <span style="font-size:21px;"><strong>Rex Geitner</strong></span><br />
      <span style="font-size:18px;top: -30px;">AGV Viticulturist</span>
    </div>
    <div class="col-md-6" style="text-align: right;">
      <img style="width: 150px;margin-top:3px;margin-right:20px;text-align: right;" src="../../assets/images/agv/rex-bio-image.jpg"/>
    </div>
  </div>
  <!-- <h2 mat-dialog-title>Rex Geitner<br />AGV Viticulturist</h2><br />-->
  <mat-dialog-content style="margin-top:20px;" class="mat-typography">
  <p>
    Rex had been involved in wine making and viticulture in the Napa Valley for over twenty-five years.
    He was a member of the American Society of Enology and Viticulture, Napa Valley Vineyard Technical Group (Past President), and
    Napa Valley Pierce’s Disease Task Force. After relocating to the Napa Valley in 1973 from Southern California,
    Rex helped develop a complex mountain vineyard now known as Sterling Vineyard’s Diamond Mountain Ranch.
    He then continued upon a wine making and viticultural career that is highlighted by such illustrious names as Diamond Creek Vineyard (Diamond Mountain),
    Robert Keenan Winery (Spring Mountain), Stag’s Leap Wine Cellars, Domaine Carneros, and Spring Mountain Winery.
    The combination of twenty-four years of hands-on experience and in-depth studies of viticulture and enology at Napa Valley College,
    Fresno State College and University of California Davis enabled Rex to provide the critical guidance and become the principal architect of
    Andrew Geoffrey Vineyards high mountain vineyard. Using the best of innovative technology woven with the wisdom of traditional methodology he
    developed and built the AGV vineyard system that is environmentally sustainable and eco-sensitive.
    Rex passed on June 12, 2013 after a brief fight with cancer.  He is remembered and missed every day.
  </p>
  <div class="row" style="margin-top:20px;">
   <div class="col-md-12" style="text-align: center;">
      <img style="width:100px;" src="../../assets/images/agv/bottle-horizontal.png" />
   </div>


  </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button  class="btn btn-outline-secondary" [mat-dialog-close]="true" cdkFocusInitial>Close</button>
  </mat-dialog-actions>
  </div>
