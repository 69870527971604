import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';



@Injectable()
export class DefaultInterceptor implements HttpInterceptor {

    constructor() {

    }

    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {

    const apiKey = 'C73CA445A9154D14945FEC84229EDEB7';
    const date = new Date();
    const timezoneOffset = date.getTimezoneOffset()/60;

      if (apiKey) {
        // Clone the request and attach the token
        const authReq = req.clone({
          setHeaders: {
            'ApiKey': `${apiKey}`,
            'TIMEZONEOFFSET': `${timezoneOffset}`,
          }
        });

        return next.handle(authReq);

      }
     
  
      // If there is no token, pass the original request
      return next.handle(req);
    }

   
  }