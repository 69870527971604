import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  cartQuantity: number = 0;

  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
    this.loadScript('../../assets/js/custom/custom.min.js');
    this.loadScript('../../assets/js/custom/plugins.min.js');
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  public onNavClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  public navigateToSection(section: string) {
    window.location.hash = '';
    window.location.hash = section;
  }

  addToCart(cartItem: string){

  }



}
