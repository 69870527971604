import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { ProductVintage } from '../../../entities/ProductVintage';
import { IProduct, IStateList } from '../../../shared/interfaces';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
    providedIn: 'root'
  })
  export class ProductService {
    baseUrl: string = 'https://localhost:7005/api/';
    Products: IProduct[] = [];

    constructor(private http: HttpClient) {
    }
  

  getAvailableWinesForWebstore(): Observable<IProduct[]> {
      return this.http.get<IProduct[]>(`${this.baseUrl}product/getallavailableproducts`);
  }


  getShippingStatesList(): Observable<IStateList[]> {
    return this.http.get<IStateList[]>(`${this.baseUrl}shipping/getshippingstateslist`);
}
}