 <!-- Preloader-->
 <div class="page-loader">
  <div class="page-loader-inner">
      <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
      </div>
  </div>
</div>
<!-- Preloader end-->
