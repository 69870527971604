import { Component, HostListener, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { BioPeterComponent } from '../team-bio/bio-peter.component';
import { BioRexComponent } from '../team-bio/bio-rex.component';
import { BioJohnComponent } from '../team-bio/bio-john.component';
import { BioSaraComponent } from '../team-bio/bio-sara.component';
import { BioAndrewComponent } from '../team-bio/bio-andrew.component';
import { BioKarenComponent } from '../team-bio/bio-karen.component';
import { BioGeoffreyComponent } from '../team-bio/bio-geoffrey.component';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonpClientBackend } from '@angular/common/http';
import { ProductVintage } from '../entities/ProductVintage';
import { AllisonComponent } from '../team-bio/allison.component';
import { IProduct } from '../shared/interfaces';
import { ProductService } from '../core/services/data/ProductService';
import { StoreStateService } from '../core/services/state/store-state.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [ProductService],
})
export class HomeComponent implements OnInit {
  productList: IProduct[];
  productVintages: ProductVintage[];
  cartQuantity: number = 0;
  midScreenSize: number = 1400;
  mobileScreenSize: number = 790;
  screenWidth: number = 0;
  screenHeight: number = 0;

  constructor(
    private viewportScroller: ViewportScroller,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public router: Router,
    private _storeStateService: StoreStateService) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.loadScript('../assets/js/custom/custom.min.js');

   
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

 

  DialogPeter() {
    const dialogRef = this.dialog.open(BioPeterComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  DialogRex() {
    const dialogRef = this.dialog.open(BioRexComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  DialogJohn() {
    const dialogRef = this.dialog.open(BioJohnComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  DialogAllison() {
    const dialogRef = this.dialog.open(AllisonComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  DialogKaren() {
    const dialogRef = this.dialog.open(BioKarenComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  DialogGeoffrey() {
    const dialogRef = this.dialog.open(BioGeoffreyComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  DialogAndrew() {
    const dialogRef = this.dialog.open(BioAndrewComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  addToCart(productName: string): void{
  //   this.cartQuantity += 1;
  //   try
  //   {
  //     this.snackBar.open('2015 750ml Cabernet Sauvignon has been added to your cart.', 'Checkout', {
  //       duration: 4000
  //     });
  // }
  // catch(e)
  // {
  //   console.log(e);
  // }
    alert('Please check back on July 26th for the re-launch of our improved e-commerce site.  The wines listed are currently available for sale.');
  }



  public navigateToSection(section: string) {
    if(section.toLowerCase() != 'order'){
      window.location.hash = '';
      window.location.hash = section;
    }
  }
}

// const wineInventoryList: ProductType[] = [
// {productId:'3B261E55-2C97-4EEA-AF4E-8DDB603E3E7B',productName:'2003 Cabernet Sauvignon (3L)',bottleSize:6,sortOrder:100,price:450.00},
// {productId:'BB495820-355A-4603-9A72-843578B15AF2',productName:'2004 Cabernet Sauvignon (375ml)',bottleSize:375,sortOrder:200,price:45.00},
// {productId:'71C55F01-9632-4801-8C51-655D1A4E75B5',productName:'2004  Cabernet Sauvignon (3L)',bottleSize:6,sortOrder:400,price:450.00},
// {productId:'3EA8F06B-3A23-4027-89C0-F7A4E88675C2',productName:'2005 Cabernet Sauvignon (375ml)',bottleSize:375,sortOrder:500,price:45.00},
// {productId:'59919B5A-FE52-E411-9714-0019B9DA2D1B',productName:'2005 Cabernet Sauvignon (750ml)                             ',bottleSize:750,sortOrder:600,price:85.00},
// {productId:'4B1FB4B8-8510-4383-82CF-AEC4CBAE0D48',productName:'2005  Cabernet Sauvignon (3L)',bottleSize:6,sortOrder:800,price:450.00},
// {productId:'8315C19A-3ED8-4F89-ADA0-C40CC9A0FABE',productName:'2006 Cabernet Sauvignon (375ml)',bottleSize:375,sortOrder:900,price:45.00},
// {productId:'C693C229-F3A4-47CA-9F17-D540851A5E18',productName:'2006 Cabernet Sauvignon (750ml)',bottleSize:750,sortOrder:1000,price:85.00},
// {productId:'3B3A270F-17AA-471D-BA76-647BAD7D07E7',productName:'2006  Cabernet Sauvignon (3L)',bottleSize:6,sortOrder:1100,price:450.00},
// {productId:'DF240812-C9C4-4AE3-9D0A-23A1BDAABFB9',productName:'2007 Cabernet Sauvignon (375ml)',bottleSize:375,sortOrder:1200,price:45.00},
// {productId:'55919B5A-FE52-E411-9714-0019B9DA2D1B',productName:'2007 Cabernet Sauvignon (750ml)                             ',bottleSize:750,sortOrder:1300,price:85.00},
// {productId:'B9F0E477-EF73-40B1-BBF6-A23AB67EBC95',productName:'2008 Cabernet Sauvignon (375ml)',bottleSize:375,sortOrder:1500,price:45.00},
// {productId:'53919B5A-FE52-E411-9714-0019B9DA2D1B',productName:'2008 Cabernet Sauvignon (750ml)                             ',bottleSize:750,sortOrder:1600,price:85.00},
// {productId:'47D46502-07B8-4A73-9431-94E768C39D68',productName:'2008  Cabernet Sauvignon (3L)',bottleSize:6,sortOrder:1800,price:450.00},
// {productId:'5D919B5A-FE52-E411-9714-0019B9DA2D1B',productName:'2009 Cabernet Sauvignon (375ml)                             ',bottleSize:375,sortOrder:1900,price:45.00},
// {productId:'51919B5A-FE52-E411-9714-0019B9DA2D1B',productName:'2009 Cabernet Sauvignon (750ml                          ',bottleSize:750,sortOrder:2000,price:85.00},
// {productId:'14D7207D-DFAD-4308-9C59-55EA4BA0F868',productName:'2011 Cabernet Sauvignon (750ml)',bottleSize:750,sortOrder:2300,price:85.00},
// {productId:'EAC86205-EA7E-4295-8756-EEC08C254C02',productName:'2012 Cabernet Sauvignon (375ml)',bottleSize:375,sortOrder:2400,price:45.00},
// {productId:'87417598-5F1B-47A4-B636-7EF24C01381C',productName:'2012 Cabernet Sauvignon (750ml)',bottleSize:750,sortOrder:2500,price:85.00},
// {productId:'32A48C0C-AA00-4A87-863F-4BABE88D0BEC',productName:'2012 Cabernet Sauvignon (1.5L)',bottleSize:1.5,sortOrder:2600,price:200.00},
// {productId:'D265E57B-B59E-4CC7-8A03-B9A0AA5E05C0',productName:'2012  Cabernet Sauvignon (3L)',bottleSize:6,sortOrder:2700,price:450.00},
// {productId:'0414C2A1-32C2-450B-8576-C78DD8CE0245',productName:'2013 Cabernet Sauvignon (750ml)',bottleSize:750,sortOrder:2900,price:85.00},
// {productId:'5B780BB8-06DE-4227-8471-86BFD6E7DF32',productName:'2013 Cabernet Sauvignon (1.5L)',bottleSize:1.5,sortOrder:3000,price:200.00},
// {productId:'B5B0765E-F80C-4CC4-A0CF-431AE1A54710',productName:'2013 Cabernet Sauvignon (3L)',bottleSize:3,sortOrder:3100,price:450.00},
// {productId:'1D159D93-F8E6-E911-A950-000D3A367193',productName:'2014 Cabernet Sauvignon (375ml)',bottleSize:375,sortOrder:3199,price:45.00},
// {productId:'51F1EB54-F7E6-E911-A950-000D3A367193',productName:'2014 Cabernet Sauvignon (750ml)',bottleSize:750,sortOrder:3200,price:85.00},
// {productId:'6BA1944D-F9E6-E911-A950-000D3A367193',productName:'2014 Cabernet Sauvignon (1.5)',bottleSize:1.5,sortOrder:3201,price:200.00},
// {productId:'7EF19780-F9E6-E911-A950-000D3A367193',productName:'2014 Cabernet Sauvignon (3.0)',bottleSize:3,sortOrder:3202,price:450.00},
// {productId:'14EE402D-F9E6-E911-A950-000D3A367193',productName:'2015 Cabernet Sauvignon (375ml)',bottleSize:375,sortOrder:3299,price:45.00},
// {productId:'BECB9075-F7E6-E911-A950-000D3A367193',productName:'2015 Cabernet Sauvignon (750ml)',bottleSize:750,sortOrder:3300,price:85.00},
// {productId:'06042761-F9E6-E911-A950-000D3A367193',productName:'2015 Cabernet Sauvignon (1.5)',bottleSize:1.5,sortOrder:3301,price:200.00},
// {productId:'C1D8106F-F9E6-E911-A950-000D3A367193',productName:'2015 Cabernet Sauvignon (3.0)',bottleSize:3,sortOrder:3302,price:450.00},
// {productId:'14EE402D-F9E6-E911-A950-000D3A367193',productName:'2016 Cabernet Sauvignon (375ml)',bottleSize:375,sortOrder:3299,price:45.00},
// {productId:'BECB9075-F7E6-E911-A950-000D3A367193',productName:'2016 Cabernet Sauvignon (750ml)',bottleSize:750,sortOrder:3300,price:85.00},
// {productId:'06042761-F9E6-E911-A950-000D3A367193',productName:'2016 Cabernet Sauvignon (1.5)',bottleSize:1.5,sortOrder:3301,price:200.00},
// {productId:'C1D8106F-F9E6-E911-A950-000D3A367193',productName:'2016 Cabernet Sauvignon (3.0)',bottleSize:3,sortOrder:3302,price:450.00},

// ];
