
<app-nav-menu></app-nav-menu>

 <!-- Wrapper-->
 <div class="wrapper bg-gray">
  <!-- Shop cart-->
  <section class="module">
      <div class="container">
          <div class="row">
              <div class="col-md-12">
                  <form method="post">
                      <div class="table-responsive">
                          <table class="table cart-table">
                              <thead>
                                  <tr>
                                      <th class="col-thumbnail">Vintage</th>
                                      <th class="col-title"></th>
                                      <th class="col-price">Price</th>
                                      <th class="col-quantity">Quantity</th>
                                      <th class="col-subtotal">Total</th>
                                      <th class="col-remove"></th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let cart of cartData">
                                      <!-- <td><a href="#"><img class="cart-thumbnail" src="../../assets/images/agv/agv-bottle.jpg" alt=""></a></td> -->
                                      <td>
                                          <h6 class="m-b-0"><a href="#">{{cart.description}}</a></h6>
                                          <p><small>Bottle Size: {{cart.bottleSize}}</small></p>
                                      </td>
                                      <td>{{cart.price | currency}}
                                      </td>
                                      <td>
                                            {{cart.quantity}}
                                          <!-- <input class="form-control" type="number" step="1" min="1" value="1"> -->
                                      </td>
                                      <td>{{cart.price | currency}}
                                      </td>
                                      <td class="cart-remove"><a href="#"><i class="ti-close"></i></a></td>
                                  </tr>
                                 
                              </tbody>
                          </table>
                      </div>
                      <div class="form-row">
                          <div class="form-group col-md-4">
                          </div>
                          <div class="form-group col-md-4 text-right"><button class="btn btn-outline-secondary">Update Cart</button></div>
                          <!-- <div class="form-group col-md-4"><a class="btn btn-block-mobile btn-outline-brand" href="#">Apply Coupon</a></div> -->
                          <!-- <div class="form-group col-md-4 text-right"><a class="btn btn-block-mobile btn-brand" href="#">Update Cart</a></div> -->
                      </div>
                      <hr class="m-t-0">
                  </form>
              </div>
          </div>
          <div class="row m-t-70">
              <div class="col-md-12">
                  <table class="table cart-table">
                      <tbody>
                          <tr>
                              <td>Subtotal</td>
                              <td class="text-right">$255.00</td>
                          </tr>
                          <tr>
                              <td>Discount</td>
                              <td class="text-right">$0.00</td>
                          </tr>
                          <tr>
                              <td>Total</td>
                              <td class="text-right">$255.00</td>
                          </tr>
                      </tbody>
                  </table>
                  <div class="text-right"><button class="btn btn-outline-secondary">Proceed to Checkout</button></div>
              </div>
          </div>
      </div>
  </section>
  <!-- Shop cart end-->
<!-- Wrapper end-->


</div>
<!-- Off canvas-->
<div class="off-canvas-sidebar">
  <div class="off-canvas-sidebar-wrapper">
      <div class="off-canvas-header"><a class="off-canvas-close" href="#"><span class="ti-close"></span></a></div>
      <div class="off-canvas-content">
          <!-- Text widget-->
          <aside class="widget widget-text">
              <div class="textwidget">
                  <p class="text-center"><img src="assets/images/logo-light-2.png" alt="" width="80px"></p>
                  <p class="text-center">See how users experience your website in realtime to see changes.</p>
              </div>
          </aside>
          <!-- Recent portfolio widget-->
          <aside class="widget widget-recent-works">
              <ul>
                  <li><a href="#"><img src="assets/images/widgets/1.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/2.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/3.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/4.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/5.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/6.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/7.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/8.jpg" alt=""></a></li>
                  <li><a href="#"><img src="assets/images/widgets/9.jpg" alt=""></a></li>
              </ul>
          </aside>
          <!-- Social Icons-->
          <ul class="social-icons text-center">
              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
              <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
              <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
          </ul>
      </div>
  </div>

<!-- Off canvas end-->

<!-- To top button--><a class="scroll-top" href="#top"><i class="fas fa-angle-up"></i></a>

