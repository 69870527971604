import { CartComponent } from './checkout/cart.component';
import { HistoryComponent } from './home/history.component';
import { HomeComponent } from './home/home.component';
import { GalleryComponent } from './gallery/gallery.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CatalogComponent } from './store/catalog.component';

const routes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'history', component: HistoryComponent},
  {path: 'gallery', component: GalleryComponent},
  {path: 'cart', component: CartComponent},
  {path: 'catalog', component: CatalogComponent},  
  {path: '', component: HomeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
