import { HistoryComponent } from './home/history.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoaderComponent } from './shared/loader.component';
import { NavbarComponent } from './shared/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MaterialModule } from './material-module';
import { BioPeterComponent } from './team-bio/bio-peter.component';
import { BioAndrewComponent } from './team-bio/bio-andrew.component';
import { BioGeoffreyComponent } from './team-bio/bio-geoffrey.component';
import { BioRexComponent } from './team-bio/bio-rex.component';
import { BioJohnComponent } from './team-bio/bio-john.component';
import { BioSaraComponent } from './team-bio/bio-sara.component';
import { BioKarenComponent } from './team-bio/bio-karen.component';
import { GalleryComponent } from './gallery/gallery.component';
import { NavMenuComponent } from './shared/nav-menu.component';
import { NavMenuHomeComponent } from './shared/nav-menu-home.component';
import { RegularPageFooterComponent } from './shared/regular-page-footer.component';
import { CartComponent } from './checkout/cart.component';
import { ProductService } from './core/services/data/ProductService';
import { VintageService } from './core/services/data/VintageService';
import { AllisonComponent } from './team-bio/allison.component';
import { CatalogComponent } from './store/catalog.component';
import { DefaultInterceptor } from './core/interceptors/default.interceptor';
import { StoreStateService } from './core/services/state/store-state.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HistoryComponent,
    LoaderComponent,
    NavbarComponent,
    BioPeterComponent,
    BioAndrewComponent,
    BioGeoffreyComponent,
    BioRexComponent,
    BioJohnComponent,
    BioSaraComponent,
    BioKarenComponent,
    GalleryComponent,
    NavMenuComponent,
    NavMenuHomeComponent,
    RegularPageFooterComponent,
    CartComponent,
    AllisonComponent,
    CatalogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule
  ],
  providers: [
    ProductService,
    VintageService,
    StoreStateService,
    {
      provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
