import { Component, OnInit } from '@angular/core';
import { ProductVintage } from '../entities/ProductVintage';
import { ProductService } from '../core/services/data/ProductService';
import { VintageService } from '../core/services/data/VintageService';
import { ICart, IProduct } from '../shared/interfaces';
import { StoreStateService } from '../core/services/state/store-state.service';
import { isFakeTouchstartFromScreenReader } from '@angular/cdk/a11y';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {

  lastVintage: number = 0;
  productList: IProduct[];
  cartData: ICart[] = [];

  constructor(
    private productService: ProductService,
    private _storeStateService: StoreStateService
  ) {
    this.getProductList();
   }

  ngOnInit(): void {
    this.loadScript('../assets/js/custom/custom.min.js');
  }


  addToCart(rowIndex: number, productId: string):void{
    let cartItem: ICart = {} as ICart;

    cartItem.quantity = +(<HTMLInputElement>document.getElementById('quantity' + rowIndex)).value;
    cartItem.productId = productId;
    cartItem.price = this.productList.find( products => products.productId == productId)!.price;
    cartItem.description = this.productList.find( products => products.productId == productId)!.productDisplayName;
    cartItem.bottleSize = this.productList.find( products => products.productId == productId)!.bottleSizeDescription;

    this.cartData.push(cartItem);

    this._storeStateService.cartData = this.cartData;

    this.calcCartQuantity();


  }

  calcCartQuantity():void{
    let quantity: number = 0;
    let ii: number = 0;

    for(ii = 0; ii < this.cartData.length; ii++){
      quantity += this.cartData[ii].quantity;
    }

    this._storeStateService.cartQuantity = quantity;

  }

  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }



  getWineAvailableByProductId(wineId: string): IProduct[]{
    let winesAvailable: IProduct[] =  [];
    let ii: number = 0;

    return winesAvailable;
  }
  
  private getProductList():void{
   
  
      this.productService.getAvailableWinesForWebstore().subscribe(
        res => {
          this.productList = res
        },
        (err:any) => {
        },
        () => {
          if(this.productList.length > 0)
              this.lastVintage = this.productList[0].vintage;
        }
      )
  }

  
  isNewVintage(vintage: number): boolean{
    let isChanged: boolean = false;

    if(vintage != this.lastVintage){
      isChanged = true;
      this.lastVintage = vintage;
    }

    return isChanged;
  }

}
